import PropTypes from "prop-types";
import {useEffect, useRef} from "react";
import internshipData from '../../data/internshipInfo/internshipInfo.json';
import SectionTitle from '../../components/SectionTitles/SectionTitle';
import InternshipInfoItem from '../../components/InternshipInfo/InternshipInfoItem.jsx';
import Parallax from 'parallax-js';


const InternshipInformation = ({ classOption }) => {
    const sceneEl = useRef(null);
    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
        relativeInput: true,
        })
        
        parallaxInstance.enable();

        return () => parallaxInstance.disable();

    }, [])
    return (
        <div className={`section section-padding-t30-b100 ${classOption}`}>
            <div className="container shape-animate homepage-text">
                <SectionTitle
                    titleOption="section-title text-center mb-lg-12 mb-sm-8 mb-xs-8"
                    title="Join Our Professional Internship Program"
                    subTitle=""
                />
                <div className="col-xl-12 col-lg-12 col-12" data-aos="fade-up">
                        <div className="about-content-area mt-0 mb-md-10 mb-10">
                            <p>We’re excited to offer an internship program (Online & Offline) designed to provide hands-on experience and professional development in <b>Web Development Technologies & Digital Marketing</b>. Our program is carefully crafted to equip interns with skills, mentorship, and training that align with industry standards, helping them to gain valuable real-world experience and prepare for a successful career.<br/><br/></p>

                            <p>Program Structure and Benefits
Our internship program includes:
<ul>
<li>Comprehensive training sessions</li>
<li>One-on-one mentorship with industry experts</li>
<li>Access to exclusive resources and networking opportunities</li>
<li>A certificate of completion</li>
</ul>

<br/></p>
                            <p>To maintain the quality of our program and cover associated costs, we require interns to contribute a fee of 8999/-. This fee supports training materials, professional guidance, and access to tools and resources designed to enrich the learning experience.<br/><br/></p>
                            <p>We understand the importance of a well-rounded, impactful internship, and we strive to deliver an experience that brings interns closer to their professional goals.</p>

                        </div>
                    </div>
                <div className="row row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6">
                    {internshipData && internshipData.map((single, key) => {
                        return(
                            <div key={key} className="col mb-6" data-aos="fade-up">
                                <InternshipInfoItem data={single} key={key} />
                            </div>
                        ); 
                    })}
                </div>

                <div className="shape shape-1" id="scene" ref={sceneEl}>
                    <span data-depth="1"><img src={process.env.PUBLIC_URL + "images/shape-animation/video-shape-1.webp"} alt="shape" /></span>
                </div>

            </div>
        </div>
    )
}

InternshipInformation.propTypes = {
    classOption: PropTypes.string
};
InternshipInformation.defaultProps = {
    classOption: "section section-padding-t90-b100"
};

export default InternshipInformation
