import PropTypes from "prop-types";
import React from 'react';
//import {Link} from "react-router-dom";
//import BlogDetails from '../../components/Blog/BlogDetails.jsx';
//import Comment from '../../components/Comment/Comment.jsx';
import SidebarWrap from '../../components/Sidebar/SidebarWrap.jsx';
import SidebarWidget from '../../components/Sidebar/SidebarWidget.jsx';
import SidebarBanner from '../../components/Sidebar/SidebarBanner.jsx';
//import SidebarTitle from '../../components/Sidebar/SidebarTitle';
//import SidebarSearch from '../../components/Sidebar/SidebarSearch.jsx';
//import SidebarCategories from '../../components/Sidebar/SidebarCategories.jsx';
//import SidebarPost from '../../components/Sidebar/SidebarPost.jsx';
//import SidebarTag from '../../components/Sidebar/SidebarTag.jsx';

const IOSAppDevelopmentContainer = () => {
    return (
        <div className="section section-padding fix">
            <div className="container">
                <div className="row mb-n10">

                    <div className="col-lg-8 col-12 order-lg-1 mb-10">
                        <div className="row row-cols-1 no-gutters">
                        <div className="blog-3 blog-details col" data-aos="fade-up">
            <div className="thumbnail">
                <img className="w-100" src="../images/blog/770/iosdevelopment.webp" alt="responsive web design" />
            </div>
            <div className="info">
                <h1>With our IOS App Development services, your website remains accessible, engaging, and effective across all devices.</h1>
                <h2 className="title">IOS App Development</h2>
                <div className="desc">
                    <p>iOS app development services involve the creation of mobile applications specifically designed for Apple&apos;s iOS devices, including iPhones, iPads, and iPod touches. These services are provided by specialized companies or developers with expertise in iOS app development.</p>

                    <p>The process of iOS app development typically includes the following stages:</p>

                    <p>Requirement Analysis: Understanding the client&apos;s needs and objectives for the app, including its target audience and key features.</p>

                    <p>UI/UX Design: Creating the app&apos;s user interface and user experience design, focusing on usability, visual appeal, and adherence to Apple&apos;s design guidelines.</p>

                    <p>Development: Writing code using programming languages such as Swift or Objective-C to build the app&apos;s functionality and features.</p>

                    <p>Testing: Conducting thorough testing to ensure the app works correctly, is free of bugs, and provides a seamless user experience.</p>

                    <p>Deployment: Preparing the app for release on the Apple App Store, including submitting it for review and approval.</p>

                    <p>Maintenance and Support: Providing ongoing support and updates to the app to ensure it remains compatible with new iOS versions and devices.</p>

                    <p>iOS app development services may also include additional features such as:</p>

                    <p>Custom App Development: Creating bespoke iOS apps tailored to the client&apos;s specific requirements.</p>
                    <p>Native App Development: Building apps that are optimized for performance and take advantage of iOS-specific features and functionalities.</p>
                    <p>Hybrid App Development: Developing apps that can run on multiple platforms, including iOS, using frameworks like React Native or Flutter.</p>
                    <p>Overall, iOS app development services aim to create high-quality, user-friendly apps that meet the client&apos;s needs and provide a seamless experience for iOS users.</p>

                </div>
            </div>
        </div>

                        </div>
                    </div>

                    <div className="col-lg-4 col-12 order-lg-2 mb-10">
                        <SidebarWrap>
                            {/* <SidebarWidget>
                                <SidebarSearch />
                            </SidebarWidget>
                            <SidebarWidget>
                                <SidebarTitle title="Categories" />
                                 <SidebarCategories />
                            </SidebarWidget> */}
                            {/* <div className="sidebar-widget">
                                <h3 className="sidebar-widget-title sidebar-widget-title">Our Services</h3>
                                <div className="sidebar-widget-content">
                                <ul className="sidebar-widget-cate-list">
                                        <li><a href="/best-web-designing-company"><span className="text">Web Designing</span></a></li>
                                        <li><a href="/web-development-company"><span className="text">Web Development</span></a></li>
                                        <li><a href="/web-maintainance-company"><span className="text">Website Maintenance</span></a></li>
                                        <li><a href="/website-redesign-agencies"><span className="text">Website ReDesign</span></a></li>
                                        <li><a href="/wordpress-development"><span className="text">WordPress Development</span></a></li>
                                        <li><a href="/digital-marketing-agency-in-hyderabad"><span className="text">Digital Marketing</span></a></li>
                                        <li><a href="/search-engine-optimization-services-in-hyderabad"><span className="text">SEO</span></a></li>
                                        <li><a href="/web-hosting-service-provider"><span className="text">Web Hosting</span></a></li>
                                        <li><a href="/web-application-development"><span className="text">Web Application Development</span></a></li>
                                        <li><a href="/logo-design-services"><span className="text">Logo Designing</span></a></li>
                                        <li><a href="/social-media-marketing-company-in-hyderabad"><span className="text">Social Media Marketing</span></a></li>
                                        <li><a href="/wordpress-training-in-hyderabad"><span className="text">WordPress Training</span></a></li>
                                        <li><a href="/web-designing-course-in-hyderabad"><span className="text">Web Development Training</span></a></li>
                                    </ul>
                                </div>
                            </div> */}
                            {/* <SidebarWidget>
                                <SidebarTitle classOption="mb-2" title="Popular Posts" />
                                <SidebarPost />
                            </SidebarWidget> */}
                            <SidebarWidget>
                                <SidebarBanner />
                            </SidebarWidget>
                            {/* <SidebarWidget>
                                <SidebarTitle title="Popular tags" />
                                <SidebarTag />
                            </SidebarWidget> */}
                        </SidebarWrap>
                    </div>

                </div>
            </div>
        </div>
    )
}
IOSAppDevelopmentContainer.propTypes = {
    data: PropTypes.object
};

export default IOSAppDevelopmentContainer;
