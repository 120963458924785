import PropTypes from "prop-types";
import React from 'react';
//import {Link} from "react-router-dom";
//import BlogDetails from '../../components/Blog/BlogDetails.jsx';
//import Comment from '../../components/Comment/Comment.jsx';
import SidebarWrap from '../../components/Sidebar/SidebarWrap.jsx';
import SidebarWidget from '../../components/Sidebar/SidebarWidget.jsx';
import SidebarBanner from '../../components/Sidebar/SidebarBanner.jsx';
//import SidebarTitle from '../../components/Sidebar/SidebarTitle';
//import SidebarSearch from '../../components/Sidebar/SidebarSearch.jsx';
//import SidebarCategories from '../../components/Sidebar/SidebarCategories.jsx';
//import SidebarPost from '../../components/Sidebar/SidebarPost.jsx';
//import SidebarTag from '../../components/Sidebar/SidebarTag.jsx';

const AndroidAppDevelopmentContainer = () => {
    return (
        <div className="section section-padding fix">
            <div className="container">
                <div className="row mb-n10">

                    <div className="col-lg-8 col-12 order-lg-1 mb-10">
                        <div className="row row-cols-1 no-gutters">
                        <div className="blog-3 blog-details col" data-aos="fade-up">
            <div className="thumbnail">
                <img className="w-100" src="../images/blog/770/androiddevelopment.webp" alt="responsive web design" />
            </div>
            <div className="info">
                <h1>With our Android App Development services, your website remains accessible, engaging, and effective across all devices.</h1>
                <h2 className="title">Android App Development</h2>
                <div className="desc">
                    <p>Android app development services encompass the process of designing, developing, and deploying mobile applications for devices running the Android operating system. These services are provided by specialized companies or developers who have expertise in Android app development.</p>

                    <p>The process typically starts with understanding the client&apos;s requirements and goals for the app. This is followed by creating a design that meets the user experience and interface guidelines of the Android platform. Development involves writing code, integrating features, and testing the app to ensure it functions as intended.</p>

                    <h3>Android app development services may include:</h3>

                    <p><b>Custom App Development:</b> Creating bespoke Android apps tailored to the client&apos;s specific needs and requirements.</p>

                    <p><b>Native App Development:</b> Developing apps using programming languages and tools specific to the Android platform, such as Java or Kotlin.</p>

                    <p><b>Hybrid App Development:</b> Building apps that can run on multiple platforms, including Android, using frameworks like React Native or Flutter.</p>

                    <p><b>UI/UX Design:</b> Designing the user interface and user experience of the app to be intuitive, visually appealing, and in line with Android design guidelines.</p>

                    <p><b>Testing and Quality Assurance:</b> Ensuring the app is free of bugs and functions correctly on a variety of devices and Android versions.</p>

                    <p><b>Deployment and Support:</b> Assisting in deploying the app to the Google Play Store and providing ongoing support and maintenance services.</p>

                    <p>Overall, Android app development services aim to create high-quality, user-friendly apps that meet the client&apos;s needs and enhance the user experience on Android devices.</p>

                </div>
            </div>
        </div>

                        </div>
                    </div>

                    <div className="col-lg-4 col-12 order-lg-2 mb-10">
                        <SidebarWrap>
                            {/* <SidebarWidget>
                                <SidebarSearch />
                            </SidebarWidget>
                            <SidebarWidget>
                                <SidebarTitle title="Categories" />
                                 <SidebarCategories />
                            </SidebarWidget> */}
                            {/* <div className="sidebar-widget">
                                <h3 className="sidebar-widget-title sidebar-widget-title">Our Services</h3>
                                <div className="sidebar-widget-content">
                                <ul className="sidebar-widget-cate-list">
                                        <li><a href="/best-web-designing-company"><span className="text">Web Designing</span></a></li>
                                        <li><a href="/web-development-company"><span className="text">Web Development</span></a></li>
                                        <li><a href="/web-maintainance-company"><span className="text">Website Maintenance</span></a></li>
                                        <li><a href="/website-redesign-agencies"><span className="text">Website ReDesign</span></a></li>
                                        <li><a href="/wordpress-development"><span className="text">WordPress Development</span></a></li>
                                        <li><a href="/digital-marketing-agency-in-hyderabad"><span className="text">Digital Marketing</span></a></li>
                                        <li><a href="/search-engine-optimization-services-in-hyderabad"><span className="text">SEO</span></a></li>
                                        <li><a href="/web-hosting-service-provider"><span className="text">Web Hosting</span></a></li>
                                        <li><a href="/web-application-development"><span className="text">Web Application Development</span></a></li>
                                        <li><a href="/logo-design-services"><span className="text">Logo Designing</span></a></li>
                                        <li><a href="/social-media-marketing-company-in-hyderabad"><span className="text">Social Media Marketing</span></a></li>
                                        <li><a href="/wordpress-training-in-hyderabad"><span className="text">WordPress Training</span></a></li>
                                        <li><a href="/web-designing-course-in-hyderabad"><span className="text">Web Development Training</span></a></li>
                                    </ul>
                                </div>
                            </div> */}
                            {/* <SidebarWidget>
                                <SidebarTitle classOption="mb-2" title="Popular Posts" />
                                <SidebarPost />
                            </SidebarWidget> */}
                            <SidebarWidget>
                                <SidebarBanner />
                            </SidebarWidget>
                            {/* <SidebarWidget>
                                <SidebarTitle title="Popular tags" />
                                <SidebarTag />
                            </SidebarWidget> */}
                        </SidebarWrap>
                    </div>

                </div>
            </div>
        </div>
    )
}
AndroidAppDevelopmentContainer.propTypes = {
    data: PropTypes.object
};

export default AndroidAppDevelopmentContainer;
