import PropTypes from "prop-types";
import React from 'react';
//import {Link} from "react-router-dom";
//import BlogDetails from '../../components/Blog/BlogDetails.jsx';
//import Comment from '../../components/Comment/Comment.jsx';
import SidebarWrap from '../../components/Sidebar/SidebarWrap.jsx';
import SidebarWidget from '../../components/Sidebar/SidebarWidget.jsx';
import SidebarBanner from '../../components/Sidebar/SidebarBanner.jsx';
//import SidebarTitle from '../../components/Sidebar/SidebarTitle';
//import SidebarSearch from '../../components/Sidebar/SidebarSearch.jsx';
//import SidebarCategories from '../../components/Sidebar/SidebarCategories.jsx';
//import SidebarPost from '../../components/Sidebar/SidebarPost.jsx';
//import SidebarTag from '../../components/Sidebar/SidebarTag.jsx';

const DigitalMarketingContainer = () => {
    return (
        <div className="section section-padding fix">
            <div className="container">
                <div className="row mb-n10">

                    <div className="col-lg-8 col-12 order-lg-1 mb-10">
                        <div className="row row-cols-1 no-gutters">
                        <div className="blog-3 blog-details col" data-aos="fade-up">
            <div className="thumbnail">
                <img className="w-100" src="../images/blog/770/digitalmarketing.webp" alt="digital marketing agency in hyderabad" />
            </div>
            <div className="info">
                <h1>Improve your Online Presence, and Conversions with our Digital Marketing Agency in Hyderabad</h1>
                <h3 className="title">Digital Marketing</h3>
                <div className="desc">
                    <p><strong>If you wish your business to be competitive in today’s digital market, you need a decent online presence, therefore the only way to accomplish this is to develop a reliable digital marketing strategy.</strong></p>

                    <p>At our digital marketing agency in Hyderabad, our services are designed to meet the needs of continuously evolving companies. </p>

                    <p>Whether it is about our pricing or our service delivery, everything is tailored to your business needs.</p>

                    <p>We have the experience and professionals to build and use your website. We have a variety of digital marketing solutions to provide services for enterprises of all sizes in any industry.</p>

                    <p>We combine innovation, creative thinking, and a thorough understanding of today’s fast-paced technological world to sell a series of services in the online world. We handle each customer project individually.</p>

                    <p>In some cases, we may focus more on SEO, while in other cases, we may study PPC, social media, or conversion optimization more deeply.</p>
                   
                    <h3>Digital Marketing Services We offer:</h3>
 

<h5>Search Engine Optimization:</h5>
<p>Our digital marketing agency in Hyderabad provides Search engine optimization, it is one of the most important factors of Internet marketing. Our virtual marketing company conducts significant keyword research, web page optimization, and off-site optimization.</p>

<h5>Search Engine Marketing: </h5>
<p>Search engine marketing is a form of paid search advertising that brings your content material to the top of search engine results pages. Our skilled group can assist customers to boost clicks on your website to optimize their AdWords accounts. The EM process can grow traffic, conversion rates, and income pace on your website.</p>

<h5>Social Media Optimization: </h5>
<p>Our digital marketing agency in Hyderabad provides Social Media Optimization in our services. The SMO service has become a necessary condition for all companies to promote their online business and generate potential customers for their business. We provide affordable social media optimization services to ensure that all enterprises can achieve valuable results.</p>

<h5>Social Media Marketing: </h5>
<p>We create social media campaigns to assist your enterprise to develop and gain your followers. Our digital advertising and marketing agency defines your goals, compares your competitors, and measures the online behavior of your customers.</p>

<h5>Email Marketing: </h5>
<p>Create, publish, and sell stunning content material to generate more visitors and construct a participating community. We at our digital marketing agency in Hyderabad create customized emails for newsletters to maintain their emails away from spam folders and inspire your clients to take suitable measures.</p>

<h5>Content Marketing: </h5>
<p>From approach to components to content material creation, publishing, distribution, and promotion, our industry-main content material advertising offers are designed to fulfill your commercial enterprise goals. Our content professionals are stored abreast of the latest news and market tendencies to make sure our content material is evolved according to Google standards.</p>

<h5>Online Reputation Management: </h5>
<p>Online reputation may affect your online success. Build your popularity and achieve rewards through our network marketing agency. Our crew makes use of the latest online popularity control software program to optimize your ORM exercise and get extra positive reviews.</p>

<p>Our digital marketing agency in Hyderabad, is determined to offer our customers incredible sales and go back on investment. We spend a variety of time discovering the success standards of our clients and formulating clever digital techniques to gain more market share.</p>

<p>Digital advertising is broadly accepted by more businesses these days, and it is critical in addition to increasing and achieving more sales. For this, you can use Livasys’ IT solutions.</p>





                </div>
            </div>
        </div>

                        </div>
                    </div>

                    <div className="col-lg-4 col-12 order-lg-2 mb-10">
                        <SidebarWrap>
                            {/* <SidebarWidget>
                                <SidebarSearch />
                            </SidebarWidget>
                            <SidebarWidget>
                                <SidebarTitle title="Categories" />
                                 <SidebarCategories />
                            </SidebarWidget> */}
                            {/* <div className="sidebar-widget">
                                <h3 className="sidebar-widget-title sidebar-widget-title">Our Services</h3>
                                <div className="sidebar-widget-content">
                                <ul className="sidebar-widget-cate-list">
                                        <li><a href="/best-web-designing-company"><span className="text">Web Designing</span></a></li>
                                        <li><a href="/web-development-company"><span className="text">Web Development</span></a></li>
                                        <li><a href="/web-maintainance-company"><span className="text">Website Maintenance</span></a></li>
                                        <li><a href="/website-redesign-agencies"><span className="text">Website ReDesign</span></a></li>
                                        <li><a href="/wordpress-development"><span className="text">WordPress Development</span></a></li>
                                        <li><a href="/digital-marketing-agency-in-hyderabad"><span className="text">Digital Marketing</span></a></li>
                                        <li><a href="/search-engine-optimization-services-in-hyderabad"><span className="text">SEO</span></a></li>
                                        <li><a href="/web-hosting-service-provider"><span className="text">Web Hosting</span></a></li>
                                        <li><a href="/web-application-development"><span className="text">Web Application Development</span></a></li>
                                        <li><a href="/logo-design-services"><span className="text">Logo Designing</span></a></li>
                                        <li><a href="/social-media-marketing-company-in-hyderabad"><span className="text">Social Media Marketing</span></a></li>
                                        <li><a href="/wordpress-training-in-hyderabad"><span className="text">WordPress Training</span></a></li>
                                        <li><a href="/web-designing-course-in-hyderabad"><span className="text">Web Development Training</span></a></li>
                                    </ul>
                                </div>
                            </div> */}
                            {/* <SidebarWidget>
                                <SidebarTitle classOption="mb-2" title="Popular Posts" />
                                <SidebarPost />
                            </SidebarWidget> */}
                            <SidebarWidget>
                                <SidebarBanner />
                            </SidebarWidget>
                            {/* <SidebarWidget>
                                <SidebarTitle title="Popular tags" />
                                <SidebarTag />
                            </SidebarWidget> */}
                        </SidebarWrap>
                    </div>

                </div>
            </div>
        </div>
    )
}
DigitalMarketingContainer.propTypes = {
    data: PropTypes.object
};

export default DigitalMarketingContainer;
