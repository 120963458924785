import PropTypes from "prop-types";
import React from 'react';
//import {Link} from "react-router-dom";
//import BlogDetails from '../../components/Blog/BlogDetails.jsx';
//import Comment from '../../components/Comment/Comment.jsx';
import SidebarWrap from '../../components/Sidebar/SidebarWrap.jsx';
import SidebarWidget from '../../components/Sidebar/SidebarWidget.jsx';
import SidebarBanner from '../../components/Sidebar/SidebarBanner.jsx';
//import SidebarTitle from '../../components/Sidebar/SidebarTitle';
//import SidebarSearch from '../../components/Sidebar/SidebarSearch.jsx';
//import SidebarCategories from '../../components/Sidebar/SidebarCategories.jsx';
//import SidebarPost from '../../components/Sidebar/SidebarPost.jsx';
//import SidebarTag from '../../components/Sidebar/SidebarTag.jsx';

const CyberSecurityContainer = () => {
    return (
        <div className="section section-padding fix">
            <div className="container">
                <div className="row mb-n10">

                    <div className="col-lg-8 col-12 order-lg-1 mb-10">
                        <div className="row row-cols-1 no-gutters">
                        <div className="blog-3 blog-details col" data-aos="fade-up">
            <div className="thumbnail">
                <img className="w-100" src="../images/blog/770/cybersecurity.webp" alt="responsive web design" />
            </div>
            <div className="info">
                <h1>With our Cyber Security services, your website remains accessible, engaging, and effective across all devices.</h1>
                <h2 className="title">Cyber Security</h2>
                <div className="desc">
                    <p>Cybersecurity services encompass a range of practices, technologies, and processes designed to protect computer systems, networks, and data from cyber threats. These services are essential for businesses and organizations of all sizes to safeguard their digital assets and sensitive information.</p>

                    <p>Key aspects of cybersecurity services include:</p>

                    <p>Risk Assessment: Identifying and assessing potential cybersecurity risks and vulnerabilities within an organization&apos;s IT infrastructure.</p>

                    <p>Security Audits: Conducting regular audits and assessments to evaluate the effectiveness of existing security measures and identify areas for improvement.</p>

                    <p>Incident Response: Developing and implementing protocols for responding to cybersecurity incidents, such as data breaches or malware attacks, to minimize damage and restore operations quickly.</p>

                    <p>Security Awareness Training: Educating employees about cybersecurity best practices and raising awareness about potential threats to reduce the risk of human error.</p>

                    <p>Security Monitoring: Continuously monitoring networks and systems for suspicious activity or potential security breaches, using tools such as intrusion detection systems (IDS) and security information and event management (SIEM) systems.</p>

                    <p>Vulnerability Management: Identifying and patching vulnerabilities in software and systems to reduce the risk of exploitation by cyber attackers.</p>

                    <p>Data Protection: Implementing measures such as encryption, access controls, and data loss prevention (DLP) to protect sensitive data from unauthorized access or disclosure.</p>

                    <p>Compliance: Ensuring compliance with relevant cybersecurity regulations and standards, such as GDPR, HIPAA, or PCI DSS, to avoid penalties and protect reputation.</p>

                    <p>Overall, cybersecurity services are essential for protecting organizations against a wide range of cyber threats, including malware, ransomware, phishing attacks, and insider threats. By implementing robust cybersecurity measures, businesses can mitigate risks and safeguard their digital assets and reputation.</p>

                </div>
            </div>
        </div>

                        </div>
                    </div>

                    <div className="col-lg-4 col-12 order-lg-2 mb-10">
                        <SidebarWrap>
                            {/* <SidebarWidget>
                                <SidebarSearch />
                            </SidebarWidget>
                            <SidebarWidget>
                                <SidebarTitle title="Categories" />
                                 <SidebarCategories />
                            </SidebarWidget> */}
                            {/* <div className="sidebar-widget">
                                <h3 className="sidebar-widget-title sidebar-widget-title">Our Services</h3>
                                <div className="sidebar-widget-content">
                                <ul className="sidebar-widget-cate-list">
                                        <li><a href="/best-web-designing-company"><span className="text">Web Designing</span></a></li>
                                        <li><a href="/web-development-company"><span className="text">Web Development</span></a></li>
                                        <li><a href="/web-maintainance-company"><span className="text">Website Maintenance</span></a></li>
                                        <li><a href="/website-redesign-agencies"><span className="text">Website ReDesign</span></a></li>
                                        <li><a href="/wordpress-development"><span className="text">WordPress Development</span></a></li>
                                        <li><a href="/digital-marketing-agency-in-hyderabad"><span className="text">Digital Marketing</span></a></li>
                                        <li><a href="/search-engine-optimization-services-in-hyderabad"><span className="text">SEO</span></a></li>
                                        <li><a href="/web-hosting-service-provider"><span className="text">Web Hosting</span></a></li>
                                        <li><a href="/web-application-development"><span className="text">Web Application Development</span></a></li>
                                        <li><a href="/logo-design-services"><span className="text">Logo Designing</span></a></li>
                                        <li><a href="/social-media-marketing-company-in-hyderabad"><span className="text">Social Media Marketing</span></a></li>
                                        <li><a href="/wordpress-training-in-hyderabad"><span className="text">WordPress Training</span></a></li>
                                        <li><a href="/web-designing-course-in-hyderabad"><span className="text">Web Development Training</span></a></li>
                                    </ul>
                                </div>
                            </div> */}
                            {/* <SidebarWidget>
                                <SidebarTitle classOption="mb-2" title="Popular Posts" />
                                <SidebarPost />
                            </SidebarWidget> */}
                            <SidebarWidget>
                                <SidebarBanner />
                            </SidebarWidget>
                            {/* <SidebarWidget>
                                <SidebarTitle title="Popular tags" />
                                <SidebarTag />
                            </SidebarWidget> */}
                        </SidebarWrap>
                    </div>

                </div>
            </div>
        </div>
    )
}
CyberSecurityContainer.propTypes = {
    data: PropTypes.object
};

export default CyberSecurityContainer;
