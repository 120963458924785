import PropTypes from "prop-types";
import React from 'react';
//import {Link} from "react-router-dom";
//import BlogDetails from '../../components/Blog/BlogDetails.jsx';
//import Comment from '../../components/Comment/Comment.jsx';
import SidebarWrap from '../../components/Sidebar/SidebarWrap.jsx';
import SidebarWidget from '../../components/Sidebar/SidebarWidget.jsx';
import SidebarBanner from '../../components/Sidebar/SidebarBanner.jsx';
//import SidebarTitle from '../../components/Sidebar/SidebarTitle';
//import SidebarSearch from '../../components/Sidebar/SidebarSearch.jsx';
//import SidebarCategories from '../../components/Sidebar/SidebarCategories.jsx';
//import SidebarPost from '../../components/Sidebar/SidebarPost.jsx';
//import SidebarTag from '../../components/Sidebar/SidebarTag.jsx';

const WebDesigningClassroomTrainingContainer = () => {
    return (
        <div className="section section-padding fix">
            <div className="container">
                <div className="row mb-n10">

                    <div className="col-lg-8 col-12 order-lg-1 mb-10">
                        <div className="row row-cols-1 no-gutters">
                        <div className="blog-3 blog-details col" data-aos="fade-up">
            <div className="thumbnail">
                <img className="w-100" src="../images/blog/770/20.webp" alt="Web Designing Course in Hyderabad" />
            </div>
            <div className="info">
                <h1>Master the Art of Web Designing with our best Web Designing Course in Hyderabad.</h1>
                <h3 className="title">Web Designing Classroom Training</h3>
                <div className="desc">
                    <p><strong>Livasys IT Solutions provides the best web designing course in Hyderabad. With the help of web design course, you can design and create stunning websites. Web design is the practice of designing and coding using images and functions. We offer both online and offline course training.</strong></p>

                    <p>Companies are looking for qualified web designers who can create responsive websites using the latest tools and technologies. Learn web design with our tutors.</p>

                    <p>It will enable you to develop a professional portfolio that helps you showcase your web design skills. Our Web design course in Hyderabad include training in standard design and technology additionally programming languages commonly used in web design.</p>

                    <p>With hands-on training from industry experts and real-world projects, our web design course will give you the skills you need to build and deploy a responsive website.</p>

                    <p>This course explains how to create websites using exceptional themes such as HTML5, CSS3, XHTML, DHTML, Adobe Photoshop, Adobe Dreamweaver, Responsive Design, Bootstrap, JavaScript, jQuery, SEO Basics, Web Hosting, and WordPress.</p>

                    <p>By enrolling in the web designing course in Hyderabad, aspiring designers gain access to a energetic learning community and a wealth of practical knowledge.</p>

                    <p>Our instructors will offer web design training primarily based on modern industry requirements. At the end of the finalization of our web design courses, you’ll be capable of creating websites yourself. Creativity and experience go hand in hand when it comes to web design. Websites are an essential part of any home business.</p>

                    <p>Livasys IT Solutions provide the best web designing course in Hyderabad with Live Projects and deliver in-depth information in graphic web design, interface design, copyrights, including standardized code and proprietary software, user interface design, and search engine optimization.</p>
                    

                    <h3>Advantages of Website Design Course:</h3>
                    <p>If you want to become a professional web designer, we recommend you to take our Top-rated web designing course in Hyderabad. The advantage of our course is that it is completely based on both theory and practice aspects of web design. It includes: </p>
                    <ul>
                        <li>Adobe Photoshop</li>
                        <li>HTML</li>
                        <li>CSS</li>
                        <li>Adobe Dreamweaver</li>
                        <li>Responsive Design</li>
                        <li>JavaScript</li>
                        <li>jQuery</li>
                        <li>Bootstrap</li>
                        <li>SEO Basics – On-Page SEO</li>
                        <li>Web Hosting</li>
                        <li>WordPress</li>
                    </ul>
                    <h3>Career options after the Web Designing Course:</h3>
                    <p>Our web designing course in Hyderabad offers industry-oriented training, preparing students for successful careers in web design agencies, startups, or as freelancers.</p>
                    <p>After obtaining the required qualifications, a web designer can apply for various positions in the private and public sectors, however, there are job opportunities for web designers in both regions, and both the private sector and the government have similar requirements.</p>
                    <ol>
                        <li>Advertising, Design Studios, Media Agency</li>
                        <li>Web Designer Take a Leading Role in the Software / IT Sector</li>
                        <li>You Can Also Start Freelance Web Designer </li>
                        <li>Start A Career as User Interface Developer</li>
                        <li>UX Specialist / Specialist by user experience</li>
                        <li>You can work in website development companies</li>
                        <li>Home Publishing </li>
                        <li>Government and Semi-public organizations</li>
                    </ol>
                    <p>Now that modern business trends are taking off, online designers are more needed than ever.</p>
                    <p>However, this is a constantly changing industry, so only those who wish to constantly improve their qualifications can hope for success.</p>
                    <p>In this fun and exciting profession, you can create and develop your skills every day; however, you should feel comfortable working alone and as part of a team.</p>
                    <p>If you want to apply your innovative ideas and learn how to use them to improve the effectiveness of your career, this web design training course can help you immensely.</p>

                </div>
            </div>
        </div>

                        </div>
                    </div>

                    <div className="col-lg-4 col-12 order-lg-2 mb-10">
                        <SidebarWrap>
                            {/* <SidebarWidget>
                                <SidebarSearch />
                            </SidebarWidget>
                            <SidebarWidget>
                                <SidebarTitle title="Categories" />
                                 <SidebarCategories />
                            </SidebarWidget> */}
                            {/* <div className="sidebar-widget">
                                <h3 className="sidebar-widget-title sidebar-widget-title">Our Services</h3>
                                <div className="sidebar-widget-content">
                                <ul className="sidebar-widget-cate-list">
                                        <li><a href="/best-web-designing-company"><span className="text">Web Designing</span></a></li>
                                        <li><a href="/web-development-company"><span className="text">Web Development</span></a></li>
                                        <li><a href="/web-maintainance-company"><span className="text">Website Maintenance</span></a></li>
                                        <li><a href="/website-redesign-agencies"><span className="text">Website ReDesign</span></a></li>
                                        <li><a href="/wordpress-development"><span className="text">WordPress Development</span></a></li>
                                        <li><a href="/digital-marketing-agency-in-hyderabad"><span className="text">Digital Marketing</span></a></li>
                                        <li><a href="/search-engine-optimization-services-in-hyderabad"><span className="text">SEO</span></a></li>
                                        <li><a href="/web-hosting-service-provider"><span className="text">Web Hosting</span></a></li>
                                        <li><a href="/web-application-development"><span className="text">Web Application Development</span></a></li>
                                        <li><a href="/logo-design-services"><span className="text">Logo Designing</span></a></li>
                                        <li><a href="/social-media-marketing-company-in-hyderabad"><span className="text">Social Media Marketing</span></a></li>
                                        <li><a href="/wordpress-training-in-hyderabad"><span className="text">WordPress Training</span></a></li>
                                        <li><a href="/web-designing-course-in-hyderabad"><span className="text">Web Development Training</span></a></li>
                                    </ul>
                                </div>
                            </div> */}
                            {/* <SidebarWidget>
                                <SidebarTitle classOption="mb-2" title="Popular Posts" />
                                <SidebarPost />
                            </SidebarWidget> */}
                            <SidebarWidget>
                                <SidebarBanner />
                            </SidebarWidget>
                            {/* <SidebarWidget>
                                <SidebarTitle title="Popular tags" />
                                <SidebarTag />
                            </SidebarWidget> */}
                        </SidebarWrap>
                    </div>

                </div>
            </div>
        </div>
    )
}
WebDesigningClassroomTrainingContainer.propTypes = {
    data: PropTypes.object
};

export default WebDesigningClassroomTrainingContainer;
