import React from 'react';
// import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import InternshipInformation from '../container/InternshipInformation/InternshipInformation';
//import InternshipFromContainer from '../container/InternshipFromContainer/InternshipFromContainer';
//import SectionTitle from '../components/SectionTitles/SectionTitle';
import InternshipForm from '../components/InternshipForm/InternshipForm.jsx';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import MetaTags from 'react-meta-tags';

const Internship = () => {
    return (
        <React.Fragment>
            <MetaTags>
                <title>Paid / Unpaid Internships Available - Livasys IT Solutions | Hyderabad | India</title>
                <meta name="description" content="Paid and Unpaid Internships Available at Livasys IT Solutions for both Online and Offline. Our team is ready to assist you with web design & development, digital marketing, graphic design, domain & hosting services. " />
                <meta name="keywords" content="internship, summer internship, it company internship, it companies in hyderabad with internship options, it companies in hyderabad internship details, python internship, software internship, website designing company internship" />
                <meta property="og:title" content="Paid / Unpaid Internships - Livasys IT Solutions | Hyderabad | India" />
                <meta property="og:image" content="images/blog/770/32.webp" />
            </MetaTags>
            <Header />
            <Breadcrumb 
                title="Internship in Hyderabad"
                content="Home"
                contentTwo="Internship"
            />
            <InternshipInformation />
            <div className="contact-form-section section section-padding-t70-b100 bg-primary-blue">
                <div className="container">
                    <div className="row">
                        <div className="offset-lg-2 col-lg-8">
                            <InternshipForm />
                        </div>
                    </div>
                </div>
            </div>
           {/* <InternshipFromContainer /> */}
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default Internship;

