import PropTypes from "prop-types";
import React from 'react';
//import {Link} from "react-router-dom";
//import BlogDetails from '../../components/Blog/BlogDetails.jsx';
//import Comment from '../../components/Comment/Comment.jsx';
import SidebarWrap from '../../components/Sidebar/SidebarWrap.jsx';
import SidebarWidget from '../../components/Sidebar/SidebarWidget.jsx';
import SidebarBanner from '../../components/Sidebar/SidebarBanner.jsx';
//import SidebarTitle from '../../components/Sidebar/SidebarTitle';
//import SidebarSearch from '../../components/Sidebar/SidebarSearch.jsx';
//import SidebarCategories from '../../components/Sidebar/SidebarCategories.jsx';
//import SidebarPost from '../../components/Sidebar/SidebarPost.jsx';
//import SidebarTag from '../../components/Sidebar/SidebarTag.jsx';

const LocalSEOContainer = () => {
    return (
        <div className="section section-padding fix">
            <div className="container">
                <div className="row mb-n10">

                    <div className="col-lg-8 col-12 order-lg-1 mb-10">
                        <div className="row row-cols-1 no-gutters">
                        <div className="blog-3 blog-details col" data-aos="fade-up">
            <div className="thumbnail">
                <img className="w-100" src="../images/blog/770/localseo.webp" alt="local seo services" />
            </div>
            <div className="info">
                <h1>Enhance your local presence with our best Local SEO Services</h1>
                <h3 className="title">Local SEO</h3>
                <div className="desc">
                    <p><strong>Livasys IT Solutions provides customized local SEO services for all types of enterprises, from Small to Large Businesses.</strong></p>

                    <p>We work with a planned and structured optimization strategy that will help increase store visits, increase local brand awareness and make it easier to search on Google Maps and Google search.</p>

                   
                    <h3>WHY CHOOSE LOCAL SEO SERVICES:</h3>
                    <p>In today’s competitive digital environment, investing in the best local SEO services can mean the difference between a thriving business and a failed establishment. More than 40% of Internet searches are now location-based.</p>
                    <p>It is called local intent. In other words, nearly half of the searches made on Google include the name of a city, town, county, or zip code.</p>
                    <p>Local SEO ensures you are in the right place, in front of the right people at the right time. </p>
                    <p>SEO is an integral part of local marketing as site extensions, localized keywords, and community-focused content can increase brand and website awareness for those in your area. Bring your business to the forefront of the region and help you achieve sustainable success.</p>
                    <h3>Some of the Local SEO Solutions:</h3>
                    <ul>
                        <li>Keywords Targeting </li>
                        <li>Local Link Building & Listing </li>
                        <li>Local Optimization </li>
                        <li>Google My Business </li>
                        <li>Landing Pages and much more</li>
                    </ul>
                    <h3>Benefits of Local SEO Services:-</h3>
 

<h5>Creates Local Connections and Partnerships:</h5>
<p>Local SEO helps you to make your name known in your region. Businesses and consumers alike recognize the importance of building communities and keeping local economies alive. When your business starts making efforts to connect and interact with the target audience, you will see the impact, both online and offline. In addition to attracting more customers, a robust local search engine helps you establish meaningful connections and form profitable partnerships.</p>

<h5>More Relevant Traffic:</h5>
<p>When you target local consumers, your web visits are driven by people who can visit you. Taking a broad SEO for a business approach that relies on local traffic will open up many potential visits for you. While this wide reach seems significant, the chances of becoming a paying customer are pretty slim.</p>

<h5>Higher Conversion Rate:</h5>
<p>An increase in relevant traffic leads to growth in sales. When local customers are ready to make a purchase, establishing authority in your area increases their chances of becoming a customer. If local search engines are not prepared to close the deal, they will be delighted with your proximity and optimal availability. I know that you are local.</p>

<h5>Support Mobile SEO:</h5>
<p>72% of consumers who performed a local search visited a store within a five-mile radius. 28% are looking for something nearby, which leads to a purchase. Since 2015, the number of mobile searches for “where to buy” has grown by 85%. 82% of people use their phones to check for potential purchases in a store. Our team can capitalize on all of these trends and help you achieve maximum online visibility for your local business.</p>
<p>Local Search Engine Optimization is a highly targeted online marketing method. It is not the same as leaving flyers on your porch or placing an ad in your local newspaper that a novice shopper may or may not be interested in your product or service. Our Local SEO Services allows you to rank your business on search engines and other digital marketing platforms for leads.</p>
<p>Local SEO infiltration is an impressive option for your marketing efforts. Stop wasting your time and money in unrealistically competitive markets and focus on creating meaningful experiences exactly where you need them.</p>





                </div>
            </div>
        </div>

                        </div>
                    </div>

                    <div className="col-lg-4 col-12 order-lg-2 mb-10">
                        <SidebarWrap>
                            {/* <SidebarWidget>
                                <SidebarSearch />
                            </SidebarWidget>
                            <SidebarWidget>
                                <SidebarTitle title="Categories" />
                                 <SidebarCategories />
                            </SidebarWidget> */}
                            {/* <div className="sidebar-widget">
                                <h3 className="sidebar-widget-title sidebar-widget-title">Our Services</h3>
                                <div className="sidebar-widget-content">
                                <ul className="sidebar-widget-cate-list">
                                        <li><a href="/best-web-designing-company"><span className="text">Web Designing</span></a></li>
                                        <li><a href="/web-development-company"><span className="text">Web Development</span></a></li>
                                        <li><a href="/web-maintainance-company"><span className="text">Website Maintenance</span></a></li>
                                        <li><a href="/website-redesign-agencies"><span className="text">Website ReDesign</span></a></li>
                                        <li><a href="/wordpress-development"><span className="text">WordPress Development</span></a></li>
                                        <li><a href="/digital-marketing-agency-in-hyderabad"><span className="text">Digital Marketing</span></a></li>
                                        <li><a href="/search-engine-optimization-services-in-hyderabad"><span className="text">SEO</span></a></li>
                                        <li><a href="/web-hosting-service-provider"><span className="text">Web Hosting</span></a></li>
                                        <li><a href="/web-application-development"><span className="text">Web Application Development</span></a></li>
                                        <li><a href="/logo-design-services"><span className="text">Logo Designing</span></a></li>
                                        <li><a href="/social-media-marketing-company-in-hyderabad"><span className="text">Social Media Marketing</span></a></li>
                                        <li><a href="/wordpress-training-in-hyderabad"><span className="text">WordPress Training</span></a></li>
                                        <li><a href="/web-designing-course-in-hyderabad"><span className="text">Web Development Training</span></a></li>
                                    </ul>
                                </div>
                            </div> */}
                            {/* <SidebarWidget>
                                <SidebarTitle classOption="mb-2" title="Popular Posts" />
                                <SidebarPost />
                            </SidebarWidget> */}
                            <SidebarWidget>
                                <SidebarBanner />
                            </SidebarWidget>
                            {/* <SidebarWidget>
                                <SidebarTitle title="Popular tags" />
                                <SidebarTag />
                            </SidebarWidget> */}
                        </SidebarWrap>
                    </div>

                </div>
            </div>
        </div>
    )
}
LocalSEOContainer.propTypes = {
    data: PropTypes.object
};

export default LocalSEOContainer;
