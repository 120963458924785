import React from 'react';
import Accordion, { AccordionItem, AccordionTitle, AccordionContent } from "../Accordion";

const AccordionWrap = () => {
    return (
        <div className="agency-accordion max-mb-n30">
            <Accordion>
                <AccordionItem id="one">
                    <AccordionTitle id="one">How much does it cost to develop a website?</AccordionTitle>
                    <AccordionContent id="one">Our website packages start from Rs.4999 + GST. We offer a free consultation to help identify a solution according to your business needs.</AccordionContent>
                </AccordionItem>
                <AccordionItem id="two">
                    <AccordionTitle id="two">Which technology do you use to create websites?</AccordionTitle>
                    <AccordionContent id="two">Most of our websites are developed using WordPress CMS, also we develop with ReactJs, CodeIgniter, Laravel, NodeJs and some other platforms.</AccordionContent>
                </AccordionItem>
                <AccordionItem id="three">
                    <AccordionTitle id="three">How much time does it take for website development?</AccordionTitle>
                    <AccordionContent id="three">Depending on the size and requirements of a website, we usually take 1 week to six months. We will do our best to deliver the projects within deadlines without further delaying.</AccordionContent>
                </AccordionItem>
                <AccordionItem id="four">
                    <AccordionTitle id="four">Do you write provide content & images for our website?</AccordionTitle>
                    <AccordionContent id="four">Yes, we usually provide the content and images for websites we develop, but if you have anything specific we can use it as and when needed.</AccordionContent>
                </AccordionItem>
                <AccordionItem id="five">
                    <AccordionTitle id="five">There are so many companies in Hyderabad, why choose Livasys?</AccordionTitle>
                    <AccordionContent id="five">We bring together great design, simple code, and strong content with more than 12 years experience. We won’t just build an amazing website; we’ll also help you achieve your bigger goals. We’ve been doing this for years.</AccordionContent>
                </AccordionItem>
            </Accordion>
        </div>
    )
}

export default AccordionWrap;
