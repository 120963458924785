import {useState, useEffect, useRef} from "react";
import SectionTitleTwo from '../../components/SectionTitles/SectionTitleTwo';
import Tilt from 'react-parallax-tilt';
import Parallax from 'parallax-js';

const AboutFour = () => {
    
    const [scale] = useState(1.04);
    const sceneEl = useRef(null);
    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
        relativeInput: true,
        })
        
        parallaxInstance.enable();

        return () => parallaxInstance.disable();

    }, [])
    return (
        <div className="section section-padding-top about-section-padding-bottom-200">
            <div className="container">
                <div className="row">

                    <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
                        <div className="about-content-area mt-0 mb-md-10 mb-10">
                            <SectionTitleTwo 
                                subTitle="Web design and digital marketing"
                                title="We are here to manage your online presence with experience"
                            />
                            <p>Livasys IT Solutions offers a complete suite of services to meet all your digital needs. From creative web design and flawless web development to strategic digital marketing, eye-catching graphic design, and reliable domain and hosting services, we have you covered. With our expertise and commitment to excellence, we help businesses establish a strong online presence and drive success in the digital landscape. <br/><br/></p>
                            <p>Our talented team of designers and developers work closely with you to create visually stunning and user-friendly websites. We understand that a well-designed website is not just satisfying but also plays a crucial role in attracting and engaging visitors. Whether you need a simple business website or a complex e-commerce platform, we have the expertise to bring your vision to life.</p>

                        </div>
                    </div>

                    <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up" data-aos-delay="300">
                        <div className="about-image-area about-shape-animation right-0 mr-0">
                            <div className="about-image js-tilt">
                                <Tilt scale={scale} transitionSpeed={4000}>
                                    <img src={process.env.PUBLIC_URL + "images/about/about-1.webp"} alt="livasys it solutions" />
                                </Tilt>
                            </div>
                            <div className="about-image js-tilt">
                                <Tilt scale={scale} transitionSpeed={4000}>
                                    <img src={process.env.PUBLIC_URL + "images/about/about-2.webp"} alt="livasys it solutions" />
                                </Tilt>
                            </div>
                            <div className="shape shape-1" id="scene" ref={sceneEl}>
                                <span data-depth="1"><img src={process.env.PUBLIC_URL + "images/shape-animation/video-shape-1.webp"} alt="" /></span>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-12 col-lg-12 col-12" data-aos="fade-up">
                    <div className="about-content-area mt-0 mb-md-10 mb-10">
                            <p>We believe that a successful website should be enhance by effective digital marketing strategies. Our digital marketing experts employ a data-driven approach to identify your target audience, optimize your online visibility, and drive relevant traffic to your website. From search engine optimization (SEO) and pay-per-click (PPC) advertising to social media marketing, and content creation, we have the skills to boost your online presence and maximize your ROI.</p>
<br/>
                            <p>Our graphic design services add the perfect finishing touch to your brand. Our creative team can design innovative logos, compelling graphics, and visually appealing marketing materials that align with your brand identity and reflect with your target audience.</p>
                            </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default AboutFour;
