import PropTypes from "prop-types";
import React from 'react';
//import {Link} from "react-router-dom";
//import BlogDetails from '../../components/Blog/BlogDetails.jsx';
//import Comment from '../../components/Comment/Comment.jsx';
import SidebarWrap from '../../components/Sidebar/SidebarWrap.jsx';
import SidebarWidget from '../../components/Sidebar/SidebarWidget.jsx';
import SidebarBanner from '../../components/Sidebar/SidebarBanner.jsx';
//import SidebarTitle from '../../components/Sidebar/SidebarTitle';
//import SidebarSearch from '../../components/Sidebar/SidebarSearch.jsx';
//import SidebarCategories from '../../components/Sidebar/SidebarCategories.jsx';
//import SidebarPost from '../../components/Sidebar/SidebarPost.jsx';
//import SidebarTag from '../../components/Sidebar/SidebarTag.jsx';

const WebDesigningContainer = () => {
    return (
        <div className="section section-padding fix">
            <div className="container">
                <div className="row mb-n10">

                    <div className="col-lg-8 col-12 order-lg-1 mb-10">
                        <div className="row row-cols-1 no-gutters">
                        <div className="blog-3 blog-details col" data-aos="fade-up">
            <div className="thumbnail">
                <img className="w-100" src="../images/blog/770/webdesign.webp" alt="Website designers in hyderabad" />
            </div>
            <div className="info">
                <h1 className="title">Take your online presence to the next level</h1>
                <div className="desc">
                    <p><strong>In a world where first impressions are everything, your website should captivate, engage, and leave a lasting impression. At Livasys IT Solutions, we’re not just building websites—we’re crafting experiences that reflect who you are and connect with your audience. Based in Hyderabad, our team is known for delivering innovative, visually appealing, and results-driven web design solutions that drive real growth for businesses like yours.</strong></p>
                    <h3>Why Livasys IT Solutions Stands Out in Web Design</h3>
                    <p>At Livasys, we believe in more than just aesthetics; we focus on building websites that are strategic, user-friendly, and optimized for results. Our designers and developers take the time to understand your business inside and out. We listen, collaborate, and craft custom solutions that speak to your brand’s personality and goals. Whether you’re a small business aiming for local outreach or an established company targeting global markets, we create designs that elevate your online presence.</p>

                    <h3>Key Benefits of Partnering with Livasys IT Solutions</h3>
 

<h5>1. Enhanced SEO for Better Visibility:</h5>

<p>A visually stunning website is important, but it needs to be found! Our team integrates SEO best practices into every aspect of your site’s design, from page structure to responsive layouts. By focusing on these elements, we ensure your website stands out in search engines, helping you reach your audience faster.</p>
<h5>2. Making a Strong First Impression</h5>
<p>Your website’s design affects how your audience perceives your brand instantly. A well-designed site keeps visitors engaged, encourages them to explore, and guides them toward action. At Livasys, we prioritize creating a compelling first impression that not only grabs attention but also encourages visitors to stay, explore, and engage with your business.</p>
<h5>3. Reducing Bounce Rates with Engaging Design</h5>
<p>A professionally designed website does more than look good; it keeps visitors interested. By ensuring seamless navigation, optimized loading speeds, and intuitive layouts, we make it easy for users to explore what your site has to offer. This keeps them moving beyond the homepage, increasing the chances of conversion and creating meaningful interactions.</p>
<h5>4. Driving Revenue Growth</h5>
<p>Effective web design is more than an online brochure. With strategic calls-to-action, intuitive user paths, and optimized functionality, a great design can convert visitors into loyal customers. By investing in a high-quality website designed by Livasys, you’re not just building an online presence—you’re setting the stage for tangible business growth and increased revenue.</p>
<h3>What You Can Expect from Livasys IT Solutions</h3>
Our team offers a full suite of web design services to help your business thrive online. Here’s a look at what we bring to the table:
<ul>
    <li>Custom Styling: Your brand is unique, and your website should be, too. We create designs that capture your brand’s essence and make a memorable impact.</li>
    <li>Responsive Design: With more users browsing on mobile, our responsive designs ensure your site looks stunning on any device, be it a smartphone, tablet, or desktop.</li>
    <li>SEO Integration: Every website we create is built with SEO in mind, ensuring better visibility and reach. By optimizing everything from loading speeds to structured content, we help your site rise in search rankings.</li>
    <li>E-commerce Functionality: For businesses looking to sell online, our e-commerce solutions offer seamless, secure shopping experiences that drive sales and foster customer loyalty.</li>
    <li>Ongoing Support and Maintenance: Our relationship doesn’t end with the launch. We’re here to support, update, and maintain your website so it continues to perform and grow with your business.</li>
    </ul>
<h3>Livasys: Going Beyond Web Design</h3>
<p>At Livasys, we see web design as just the beginning. From SEO and content creation to integration and beyond, we offer comprehensive services to help you build and maintain a powerful digital footprint. We’re your partner in growth, working with you to adapt to the changing digital landscape and meet your business goals.</p>
<h3>Let’s Build Something Great Together</h3>
<p>Your website should be a reflection of everything your business stands for. With our skilled team of designers and developers, we’ll help you translate your vision into an engaging, user-friendly, and effective online presence. </p><h3>We’re passionate about seeing our clients succeed, and we’re here to help you every step of the way.
Ready to improve your online presence? Contact us at Livasys IT Solutions today!</h3>
                </div>
            </div>
        </div>

                        </div>
                    </div>

                    <div className="col-lg-4 col-12 order-lg-2 mb-10">
                        <SidebarWrap>
                            {/* <SidebarWidget>
                                <SidebarSearch />
                            </SidebarWidget>
                            <SidebarWidget>
                                <SidebarTitle title="Categories" />
                                 <SidebarCategories />
                            </SidebarWidget> */}
                            {/* <div className="sidebar-widget">
                                <h3 className="sidebar-widget-title sidebar-widget-title">Our Services</h3>
                                <div className="sidebar-widget-content">
                                <ul className="sidebar-widget-cate-list">
                                        <li><a href="/best-web-designing-company"><span className="text">Web Designing</span></a></li>
                                        <li><a href="/web-development-company"><span className="text">Web Development</span></a></li>
                                        <li><a href="/web-maintainance-company"><span className="text">Website Maintenance</span></a></li>
                                        <li><a href="/website-redesign-agencies"><span className="text">Website ReDesign</span></a></li>
                                        <li><a href="/wordpress-development"><span className="text">WordPress Development</span></a></li>
                                        <li><a href="/digital-marketing-agency-in-hyderabad"><span className="text">Digital Marketing</span></a></li>
                                        <li><a href="/search-engine-optimization-services-in-hyderabad"><span className="text">SEO</span></a></li>
                                        <li><a href="/web-hosting-service-provider"><span className="text">Web Hosting</span></a></li>
                                        <li><a href="/web-application-development"><span className="text">Web Application Development</span></a></li>
                                        <li><a href="/logo-design-services"><span className="text">Logo Designing</span></a></li>
                                        <li><a href="/social-media-marketing-company-in-hyderabad"><span className="text">Social Media Marketing</span></a></li>
                                        <li><a href="/wordpress-training-in-hyderabad"><span className="text">WordPress Training</span></a></li>
                                        <li><a href="/web-designing-course-in-hyderabad"><span className="text">Web Development Training</span></a></li>
                                    </ul>
                                </div>
                            </div> */}
                            {/* <SidebarWidget>
                                <SidebarTitle classOption="mb-2" title="Popular Posts" />
                                <SidebarPost />
                            </SidebarWidget> */}
                            <SidebarWidget>
                                <SidebarBanner />
                            </SidebarWidget>
                            {/* <SidebarWidget>
                                <SidebarTitle title="Popular tags" />
                                <SidebarTag />
                            </SidebarWidget> */}
                        </SidebarWrap>
                    </div>

                </div>
            </div>
        </div>
    )
}
WebDesigningContainer.propTypes = {
    data: PropTypes.object
};

export default WebDesigningContainer;
