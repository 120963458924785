import PropTypes from "prop-types";
import React from 'react';
//import {Link} from "react-router-dom";
//import BlogDetails from '../../components/Blog/BlogDetails.jsx';
//import Comment from '../../components/Comment/Comment.jsx';
import SidebarWrap from '../../components/Sidebar/SidebarWrap.jsx';
import SidebarWidget from '../../components/Sidebar/SidebarWidget.jsx';
import SidebarBanner from '../../components/Sidebar/SidebarBanner.jsx';
//import SidebarTitle from '../../components/Sidebar/SidebarTitle';
//import SidebarSearch from '../../components/Sidebar/SidebarSearch.jsx';
//import SidebarCategories from '../../components/Sidebar/SidebarCategories.jsx';
//import SidebarPost from '../../components/Sidebar/SidebarPost.jsx';
//import SidebarTag from '../../components/Sidebar/SidebarTag.jsx';

const WordpressDevelopmentContainer = () => {
    return (
        <div className="section section-padding fix">
            <div className="container">
                <div className="row mb-n10">

                    <div className="col-lg-8 col-12 order-lg-1 mb-10">
                        <div className="row row-cols-1 no-gutters">
                        <div className="blog-3 blog-details col" data-aos="fade-up">
            <div className="thumbnail">
                <img className="w-100" src="../images/blog/770/wordpress.webp" alt="Blog Image" />
            </div>
            <div className="info">
                <h1>Create & Improve Your Online Presence with our Wordpress Development Services.</h1><br/>
                <h3 className="title">Word Press Development</h3>
                <div className="desc">
                    <p><strong>WordPress is one of the most popular CMS all over the globe, and Livasys has a team of experts in WordPress Development with an extensive experience of 10+ years into development with WordPress.</strong></p>

                    <p>From Working on the different niches of websites like simple blogs to complex classifieds websites, job portals, reviewing websites, business websites, online training websites, car rental and property rental websites, digital marketing websites, e-commerce, NGO, and hospital & healthcare websites.</p>

                    <p>Our WordPress Development team works innovatively to develop many effective services to help you grow your business. The main goal of our team is to create a stunning and attractive website, which assists your website to encourage more customers to visit your website multiple times.</p>

                    <p>Our team is constantly providing support in WordPress for clients from various parts of the globe like the United States, United Kingdom, Australia, Canada, UAE, Japan, India, Saudi Arabia, and more in terms of their websites development, maintenance, malware removal, bug fixing, and updates.</p>
                   
                    <h3>Here is why you need to get a WordPress Development done with our team.</h3>
 

<h5>Customization with WordPress:</h5>
<p>Whether you are looking for custom theme development or plugin development, we have the best developers to take up your project and work on it as per your requirements. We bring the ideas into reality with our excellent level of customization and development in WordPress. Our core WP team will work closely with our clients and help them accomplish the exact results as per their requirements.</p>

<h5>PSD to WordPress Conversion:</h5>
<p>If you are looking for a custom design for your business website, we can design the same in photoshop and then turn it into a fully functional website in WordPress. Our team loves to work on custom projects to explore the new requirements with exceptional creative and workable solutions in WordPress.</p>

<h5>WordPress Payment & Security:</h5>
<p>WordPress security can be compromised easily if you are not following certain security measures during the installation and development process, and when it comes to an e-commerce or business website which involves a payment gateway one needs to make sure that all the security measures are taken just to cut out the risk of getting your website hacked or attacked with malicious code. Our WordPress experts will be taking care of all the necessary measures to make sure that the payment gateway and the website is secure and does not possess any kind of vulnerability in the website.</p>

<h5>WordPress Migration:</h5>
<p>WordPress migration is not an easy task until and unless you are pro at it. Our team of WordPress developers makes it easy for you to migrate from one hosting to another with our hassle-free process of WP migration. Whether it’s a basic hosting or cloud server like AWS, our team will help you in both the setup and migration of the WP website and its smooth functioning.</p>





                </div>
            </div>
        </div>

                        </div>
                    </div>

                    <div className="col-lg-4 col-12 order-lg-2 mb-10">
                        <SidebarWrap>
                            {/* <SidebarWidget>
                                <SidebarSearch />
                            </SidebarWidget>
                            <SidebarWidget>
                                <SidebarTitle title="Categories" />
                                 <SidebarCategories />
                            </SidebarWidget> */}
                            {/* <div className="sidebar-widget">
                                <h3 className="sidebar-widget-title sidebar-widget-title">Our Services</h3>
                                <div className="sidebar-widget-content">
                                <ul className="sidebar-widget-cate-list">
                                        <li><a href="/best-web-designing-company"><span className="text">Web Designing</span></a></li>
                                        <li><a href="/web-development-company"><span className="text">Web Development</span></a></li>
                                        <li><a href="/web-maintainance-company"><span className="text">Website Maintenance</span></a></li>
                                        <li><a href="/website-redesign-agencies"><span className="text">Website ReDesign</span></a></li>
                                        <li><a href="/wordpress-development"><span className="text">WordPress Development</span></a></li>
                                        <li><a href="/digital-marketing-agency-in-hyderabad"><span className="text">Digital Marketing</span></a></li>
                                        <li><a href="/search-engine-optimization-services-in-hyderabad"><span className="text">SEO</span></a></li>
                                        <li><a href="/web-hosting-service-provider"><span className="text">Web Hosting</span></a></li>
                                        <li><a href="/web-application-development"><span className="text">Web Application Development</span></a></li>
                                        <li><a href="/logo-design-services"><span className="text">Logo Designing</span></a></li>
                                        <li><a href="/social-media-marketing-company-in-hyderabad"><span className="text">Social Media Marketing</span></a></li>
                                        <li><a href="/wordpress-training-in-hyderabad"><span className="text">WordPress Training</span></a></li>
                                        <li><a href="/web-designing-course-in-hyderabad"><span className="text">Web Development Training</span></a></li>
                                    </ul>
                                </div>
                            </div> */}
                            {/* <SidebarWidget>
                                <SidebarTitle classOption="mb-2" title="Popular Posts" />
                                <SidebarPost />
                            </SidebarWidget> */}
                            <SidebarWidget>
                                <SidebarBanner />
                            </SidebarWidget>
                            {/* <SidebarWidget>
                                <SidebarTitle title="Popular tags" />
                                <SidebarTag />
                            </SidebarWidget> */}
                        </SidebarWrap>
                    </div>

                </div>
            </div>
        </div>
    )
}
WordpressDevelopmentContainer.propTypes = {
    data: PropTypes.object
};

export default WordpressDevelopmentContainer;
