import React from 'react';
/**import SEO from '../components/SEO';**/
import MetaTags from 'react-meta-tags';
import Header from "../partials/header/Header";
import IntroThree from '../container/IntroSlider/IntroThree';
import HomeAboutThree from '../container/About/HomeAboutThree';
import Funfact from '../container/Funfact/Funfact';
import ServiceIconBox from '../container/service/ServiceIconBox';
import Faq from '../container/Faq/Faq';
import TestimonialContainer from '../container/Testimonial/TestimonialContainer';
//import HomeBlog from '../container/BlogGrid/HomeBlog';
import CallToAction from '../container/CallToAction/CallToAction';
//import ContactInformation from '../container/ContactInformation/ContactInformation';
import BrandContainer from '../container/Brand/BrandContainer';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';

const HomeThree = () => {
    return (
        <React.Fragment>
            <MetaTags>
                <title>One of The Leading Web Development Companies in Hyderabad</title>
                <meta name="description" content="Looking for reliable web development companies in Hyderabad? Our expert team combines innovation and expertise to deliver great outcome that meet your needs." />
                <meta name="keywords" content="web development companies in hyderabad, best web development companies in hyderabad, livasys, indian web design company, best indian web design company, top webdesign companies, web development agency, ecommerce development company, top web development companies, custom website development, web development company near me, best website development company, ecommerce web development company, website design and development company, custom web application development company, ecommerce development agency, ecommerce website development company, php development companies, web design and development company, web application development companies, seo in web development, top 10 web development companies in world, front end development companies, web and mobile app development companies, php web development companies, best indian web development companies" />
                <meta property="og:title" content="One of The Leading Web Development Companies in Hyderabad" />
                <meta property="og:image" content="images/blog/770/32.webp" />
            </MetaTags>
            <Header />
            <IntroThree />
            <HomeAboutThree />
            <Funfact classOption="section-padding bg-primary-blue"/>
            <ServiceIconBox />
            <Faq />
            {/* <PortfolioTwo /> */}
            {/* <Team /> */}
            <TestimonialContainer />
            <CallToAction />
            {/* <HomeBlog />  */}
            {/* <ContactInformation classOption="bg-primary-blue" /> */}
            <BrandContainer classOption="section-padding" />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default HomeThree;