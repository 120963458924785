import {useEffect} from "react";
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import AOS from "aos";
import NavScrollTop from './components/NavScrollTop';

// import HomeOne from './pages/HomeOne';s
// import HomeTwo from './pages/HomeTwo';
// import Service from './pages/Service';
// import Work from './pages/Work';
// import WorkDetails from './pages/WorkDetails';
// import BlogGrid from './pages/BlogGrid';
// import BlogClassic from './pages/BlogClassic';
// import BlogDetails from './pages/BlogDetails';
// import BlogCategories from './pages/BlogCategories';
// import BlogTag from './pages/BlogTag';
import HomeThree from './pages/HomeThree';
import AboutLivasys from './pages/AboutLivasys';
import Services from './pages/Services';

import WebDesigning from './pages/WebDesigning';
import WebDevelopment from './pages/WebDevelopment';
import WordpressDevelopment from './pages/WordpressDevelopment';
import WebsiteRedesignServices from './pages/WebsiteRedesignServices';
import WebMaintainance from './pages/WebMaintainance';
import GraphicDesigning from './pages/GraphicDesigning';
import LogoDesigningServices from './pages/LogoDesigningServices';
import WebHosting from './pages/WebHosting';
import WebApplicationDevelopment from './pages/WebApplicationDevelopment';
import ResponsiveWebsiteDesigning from './pages/ResponsiveWebsiteDesigning';

import DigitalMarketing from './pages/DigitalMarketing';
import SocialMediaMarketingSMM from './pages/SocialMediaMarketingSMM';
import SearchEngineOptimizationSEO from './pages/SearchEngineOptimizationSEO';
import SearchEngineMarketingSEM from './pages/SearchEngineMarketingSEM';
import SocialMediaOptimizationSMO from './pages/SocialMediaOptimizationSMO';
import LocalSEO from './pages/LocalSEO';
import ContentManagementSystem from './pages/ContentManagementSystem';
import BulkSMSServices from './pages/BulkSMSServices';
import WhatsappMarketingServices from './pages/WhatsappMarketingServices';
import DomainRegistrationServices from './pages/DomainRegistrationServices';

import AndroidAppDevelopment from './pages/AndroidAppDevelopment';
import IOSAppDevelopment from './pages/IOSAppDevelopment';
import CyberSecurity from './pages/CyberSecurity';

import Courses from './pages/Courses';

import WebDesigningCourse from './pages/WebDesigningCourse';
import DigitalMarketingCourse from './pages/DigitalMarketingCourse';
import PHPCourse from './pages/PHPCourse';
import WordpressCourse from './pages/WordpressCourse';

import OurClients from './pages/OurClients';

import Contact from './pages/Contact';

import Internship from './pages/Internship';

import ThankYou from './pages/ThankYou';

import PrivacyPolicy from './pages/PrivacyPolicy';

//import About from './pages/About';
//import WebDesigningOnlineTraining from './pages/WebDesigningOnlineTraining';
//import WordpressTraining from './pages/WordpressTraining';
//import DigitalMarketingOnlineTraining from './pages/DigitalMarketingOnlineTraining';
//import SEOClassroomTraining from './pages/SEOClassroomTraining';
//import SEOOnlineTraining from './pages/SEOOnlineTraining';
//import SEMClassroomTraining from './pages/SEMClassroomTraining';
//import SEMOnlineTraining from './pages/SEMOnlineTraining';
//import SMMClassroomTraining from './pages/SMMClassroomTraining';
//import SMMOnlineTraining from './pages/SMMOnlineTraining';
//import Hosting from './pages/Hosting';
//import ContactUs from './pages/ContactUs';
//import OurPackage from './pages/OurPackage';
//import Solution from './pages/Solution';

// CSS File Here
import "aos/dist/aos.css";
import 'react-modal-video/scss/modal-video.scss';
import './assets/scss/style.scss';

function App() {
  useEffect(() => {
    AOS.init({
        offset: 80,
        duration: 1000,
        once: true,
        easing: 'ease',
    });
    AOS.refresh();
    

    
  }, [])
  return (
      <Router>
        <NavScrollTop>
            <Switch>
              <Route path={`${process.env.PUBLIC_URL + "/"}`} exact component={HomeThree}/>
              <Route path={`${process.env.PUBLIC_URL + "/about-livasys"}`} component ={AboutLivasys} />
              
              <Route path={`${process.env.PUBLIC_URL + "/services"}`} component ={Services} />
              <Route path={`${process.env.PUBLIC_URL + "/best-web-designing-company"}`} component ={WebDesigning} />
              <Route path={`${process.env.PUBLIC_URL + "/web-development-company/"}`} component ={WebDevelopment} />
              <Route path={`${process.env.PUBLIC_URL + "/wordpress-development/"}`} component ={WordpressDevelopment} />
              <Route path={`${process.env.PUBLIC_URL + "/website-redesign-agencies/"}`} component ={WebsiteRedesignServices} />
              <Route path={`${process.env.PUBLIC_URL + "/web-maintainance-company/"}`} component ={WebMaintainance} />
              <Route path={`${process.env.PUBLIC_URL + "/graphic-design-services/"}`} component ={GraphicDesigning} />              
              <Route path={`${process.env.PUBLIC_URL + "/logo-design-services/"}`} component ={LogoDesigningServices} />
              <Route path={`${process.env.PUBLIC_URL + "/web-hosting-service-provider/"}`} component ={WebHosting} />
              <Route path={`${process.env.PUBLIC_URL + "/web-application-development/"}`} component ={WebApplicationDevelopment} />
              <Route path={`${process.env.PUBLIC_URL + "/responsive-web-design/"}`} component ={ResponsiveWebsiteDesigning} />
              <Route path={`${process.env.PUBLIC_URL + "/digital-marketing-agency-in-hyderabad/"}`} component ={DigitalMarketing} />
              <Route path={`${process.env.PUBLIC_URL + "/social-media-marketing-company-in-hyderabad/"}`} component ={SocialMediaMarketingSMM} />
              <Route path={`${process.env.PUBLIC_URL + "/search-engine-optimization-services-in-hyderabad/"}`} component ={SearchEngineOptimizationSEO} />
              <Route path={`${process.env.PUBLIC_URL + "/search-engine-marketing-services-in-hyderabad"}`} component ={SearchEngineMarketingSEM} />
              <Route path={`${process.env.PUBLIC_URL + "/social-media-optimization-services/"}`} component ={SocialMediaOptimizationSMO} />
              <Route path={`${process.env.PUBLIC_URL + "/local-seo-services-hyderabad/"}`} component ={LocalSEO} />
              <Route path={`${process.env.PUBLIC_URL + "/cms-development-companies/"}`} component ={ContentManagementSystem} />
              <Route path={`${process.env.PUBLIC_URL + "/bulk-sms-service-in-hyderabad/"}`} component ={BulkSMSServices} />
              <Route path={`${process.env.PUBLIC_URL + "/whatsapp-marketing-services/"}`} component ={WhatsappMarketingServices} />
              <Route path={`${process.env.PUBLIC_URL + "/domain-registration-services/"}`} component ={DomainRegistrationServices} />

              <Route path={`${process.env.PUBLIC_URL + "/android-app-development/"}`} component ={AndroidAppDevelopment} />
              <Route path={`${process.env.PUBLIC_URL + "/ios-app-development/"}`} component ={IOSAppDevelopment} />
              <Route path={`${process.env.PUBLIC_URL + "/cyber-security-services/"}`} component ={CyberSecurity} />

              
              <Route path={`${process.env.PUBLIC_URL + "/courses/"}`} component ={Courses} />
              <Route path={`${process.env.PUBLIC_URL + "/web-designing-course-in-hyderabad/"}`} component ={WebDesigningCourse} /> 
              <Route path={`${process.env.PUBLIC_URL + "/digital-marketing-course-in-hyderabad"}`} component ={DigitalMarketingCourse} />
              <Route path={`${process.env.PUBLIC_URL + "/php-training-in-hyderabad/"}`} component ={PHPCourse} />
              <Route path={`${process.env.PUBLIC_URL + "/wordpress-training-in-hyderabad/"}`} component ={WordpressCourse} />

              <Route path={`${process.env.PUBLIC_URL + "/our-clients/"}`} component ={OurClients} />

              <Route path={`${process.env.PUBLIC_URL + "/contact"}`} component ={Contact} />

              <Route path={`${process.env.PUBLIC_URL + "/internships-hyderabad"}`} component ={Internship} />

              <Route path={`${process.env.PUBLIC_URL + "/thankyou"}`} component ={ThankYou} />

              <Route path={`${process.env.PUBLIC_URL + "/privacy-policy"}`} component ={PrivacyPolicy} />
              
              {/* <Route path={`${process.env.PUBLIC_URL + "/home-one"}`} exact component={HomeOne}/> */}
              {/* <Route path={`${process.env.PUBLIC_URL + "/home-two"}`} component={HomeTwo}/> */}
              {/* <Route path={`${process.env.PUBLIC_URL + "/home-three"}`} component={HomeThree}/> */}
              {/* <Route path={`${process.env.PUBLIC_URL + "/work"}`} component ={Work} /> */}
              {/* <Route path={`${process.env.PUBLIC_URL + "/work-details/:id"}`} component ={WorkDetails} /> */}
              {/* <Route path={`${process.env.PUBLIC_URL + "/blog-grid"}`} component ={BlogGrid} /> */}
              {/* <Route path={`${process.env.PUBLIC_URL + "/blog-classic"}`} component ={BlogClassic} /> */}
              {/* <Route path={`${process.env.PUBLIC_URL + "/tag/:slug"}`} component ={BlogTag} /> */}
              {/* <Route path={`${process.env.PUBLIC_URL + "/category/:slug"}`}component ={BlogCategories} /> */}
              {/* <Route path={`${process.env.PUBLIC_URL + "/blog-details/:id"}`}component ={BlogDetails} /> */}
              {/* <Route component ={NotFound} /> */}
              {/* <Route path={`${process.env.PUBLIC_URL + "/about"}`} component ={About} /> */}
              {/* <Route path={`${process.env.PUBLIC_URL + "/solution/"}`} component ={Solution} />*/}
              {/* <Route path={`${process.env.PUBLIC_URL + "/web-designing-online-training/"}`} component ={WebDesigningOnlineTraining} /> */}              
              {/* <Route path={`${process.env.PUBLIC_URL + "/wordpress-training/"}`} component ={WordpressTraining} /> */}
              {/*<Route path={`${process.env.PUBLIC_URL + "/digital-marketing-online-training/"}`} component ={DigitalMarketingOnlineTraining} />*/ }
              {/* <Route path={`${process.env.PUBLIC_URL + "/seo-classroom-training/"}`} component ={SEOClassroomTraining} /> */}
              {/* <Route path={`${process.env.PUBLIC_URL + "/seo-online-training/"}`} component ={SEOOnlineTraining} /> */}
              {/* <Route path={`${process.env.PUBLIC_URL + "/sem-classroom-training/"}`} component ={SEMClassroomTraining} /> */}
              {/* <Route path={`${process.env.PUBLIC_URL + "/sem-online-training/"}`} component ={SEMOnlineTraining} /> */}
              {/* <Route path={`${process.env.PUBLIC_URL + "/smm-classroom-training/"}`} component ={SMMClassroomTraining} /> */}
              {/* <Route path={`${process.env.PUBLIC_URL + "/smm-online-training/"}`} component ={SMMOnlineTraining} /> */}
              {/* <Route path={`${process.env.PUBLIC_URL + "/hosting/"}`} component ={Hosting} /> */}
              {/* <Route path={`${process.env.PUBLIC_URL + "/contact-us/"}`} component ={ContactUs} /> */}
              {/* <Route path={`${process.env.PUBLIC_URL + "/our-package/"}`} component ={OurPackage} /> */}
              
              
            </Switch>
          </NavScrollTop>
      </Router>
  );
}

export default App;
