import PropTypes from "prop-types";
import React from 'react';
//import {Link} from "react-router-dom";
//import BlogDetails from '../../components/Blog/BlogDetails.jsx';
//import Comment from '../../components/Comment/Comment.jsx';
import SidebarWrap from '../../components/Sidebar/SidebarWrap.jsx';
import SidebarWidget from '../../components/Sidebar/SidebarWidget.jsx';
import SidebarBanner from '../../components/Sidebar/SidebarBanner.jsx';
//import SidebarTitle from '../../components/Sidebar/SidebarTitle';
//import SidebarSearch from '../../components/Sidebar/SidebarSearch.jsx';
//import SidebarCategories from '../../components/Sidebar/SidebarCategories.jsx';
//import SidebarPost from '../../components/Sidebar/SidebarPost.jsx';
//import SidebarTag from '../../components/Sidebar/SidebarTag.jsx';

const LogoDesigningServicesContainer = () => {
    return (
        <div className="section section-padding fix">
            <div className="container">
                <div className="row mb-n10">

                    <div className="col-lg-8 col-12 order-lg-1 mb-10">
                        <div className="row row-cols-1 no-gutters">
                        <div className="blog-3 blog-details col" data-aos="fade-up">
            <div className="thumbnail">
                <img className="w-100" src="../images/blog/770/logodesign.webp" alt="logo design services" />
            </div>
            <div className="info">
                <h1>Enhance your Brand identity with our Professional Logo Design Services</h1>
                <h3 className="title">Logo Design Services</h3>
                <div className="desc">
                    <p><strong>The logo is not just a minor artwork, it is the customer’s first impression of the brand. Livasys IT Solutions provides logo design services suitable for any business needs.</strong></p>

                    <p>With the Logo on Websites, packaging, labels, social media, printed materials, etc. provide your brand with the recognition it desperately needs.</p>

                    <p>Our team of professional logo designers has created stunning logos that highlight your brand online and offline.</p>

                    <p>The goal of a good logo design is to provide your company with a competitive advantage and gain the trust of customers. To achieve these two goals, the logo must be memorable, simple, and unique. Our logo designers help you create the perfect logo that meets all these criteria.</p>
                    
                    <p>Here at Livasys, we provide a wide range of Logo designs. We sum up our skills to put up the best design, for a custom-made logo for your company. Some of these logotypes are- </p>
                    <ul>
                        <li><b>Wordmarks:</b> Wordmarks or logos are independent abbreviations, company names, or product names designed to convey attributes or brand positioning.</li>
                        <li><b>Letterform:</b>Our logo design services provides a unique design that uses one or more letter shapes as a reminder of the company name.</li>
                        <li><b>Emblem:</b>A brand whose company name and graphic elements are inseparable.</li>
                        <li><b>Brand image:</b>a text image that can be recognized immediately and matches the company. These are Simplified and stylized to go with the company’s values.</li>
                        <li><b>Abstract Logo:</b> a symbol that conveys good ideas, often embodies strategic ambiguity </li>
                    </ul>

                    <h3>Our Logo Designing Process:</h3>
 

<h5>Develop Ideas:</h5>
<p>When participating in a project, we started like this: First, we read creative assignments correctly to understand the business, industry, and customer’s products/services. For all of these, we need to conduct thorough research to obtain detailed information. These details help build a design that best suits the company’s image.</p>

<h5>Create Sketch:</h5>
<p>The purpose of sketching is to find the connection between ideas and creating shapes. Some designers use sketchbooks to draw, while others start to draw directly on the computer, but in our logo design services most of our professional logo designers draw logos on paper with their hands and pencils. The sketch allows you to gain insight and come up with the original logo designing concept.</p>

<h5>Select Options:</h5>
<p>So, after we have loads of sketches, we’ll decide them against strategies and select the most promising ideas for digitization.</p>

<h5>Show the Concept: </h5>
<p>When we have completed the development of the logo concept, all we have to do is show them in the form of a presentation. In our logo design services we usually try to demonstrate to our customers 2-3 best design concepts so that they have a wide range of choices.</p>
<p>A logo that can visually indicate your company’s status or activities may be the best choice. Even a certain manipulation of your company name may be too common, With Livasys IT Solutions and its network assist, you will be able to make a stylish and unique logo for your company/Enterprises. We believe that customers should know what you are doing by looking at your logo.</p>





                </div>
            </div>
        </div>

                        </div>
                    </div>

                    <div className="col-lg-4 col-12 order-lg-2 mb-10">
                        <SidebarWrap>
                            {/* <SidebarWidget>
                                <SidebarSearch />
                            </SidebarWidget>
                            <SidebarWidget>
                                <SidebarTitle title="Categories" />
                                 <SidebarCategories />
                            </SidebarWidget> */}
                            {/* <div className="sidebar-widget">
                                <h3 className="sidebar-widget-title sidebar-widget-title">Our Services</h3>
                                <div className="sidebar-widget-content">
                                <ul className="sidebar-widget-cate-list">
                                        <li><a href="/best-web-designing-company"><span className="text">Web Designing</span></a></li>
                                        <li><a href="/web-development-company"><span className="text">Web Development</span></a></li>
                                        <li><a href="/web-maintainance-company"><span className="text">Website Maintenance</span></a></li>
                                        <li><a href="/website-redesign-agencies"><span className="text">Website ReDesign</span></a></li>
                                        <li><a href="/wordpress-development"><span className="text">WordPress Development</span></a></li>
                                        <li><a href="/digital-marketing-agency-in-hyderabad"><span className="text">Digital Marketing</span></a></li>
                                        <li><a href="/search-engine-optimization-services-in-hyderabad"><span className="text">SEO</span></a></li>
                                        <li><a href="/web-hosting-service-provider"><span className="text">Web Hosting</span></a></li>
                                        <li><a href="/web-application-development"><span className="text">Web Application Development</span></a></li>
                                        <li><a href="/logo-design-services"><span className="text">Logo Designing</span></a></li>
                                        <li><a href="/social-media-marketing-company-in-hyderabad"><span className="text">Social Media Marketing</span></a></li>
                                        <li><a href="/wordpress-training-in-hyderabad"><span className="text">WordPress Training</span></a></li>
                                        <li><a href="/web-designing-course-in-hyderabad"><span className="text">Web Development Training</span></a></li>
                                    </ul>
                                </div>
                            </div> */}
                            {/* <SidebarWidget>
                                <SidebarTitle classOption="mb-2" title="Popular Posts" />
                                <SidebarPost />
                            </SidebarWidget> */}
                            <SidebarWidget>
                                <SidebarBanner />
                            </SidebarWidget>
                            {/* <SidebarWidget>
                                <SidebarTitle title="Popular tags" />
                                <SidebarTag />
                            </SidebarWidget> */}
                        </SidebarWrap>
                    </div>

                </div>
            </div>
        </div>
    )
}
LogoDesigningServicesContainer.propTypes = {
    data: PropTypes.object
};

export default LogoDesigningServicesContainer;
