import React from 'react';
/**import SEO from '../components/SEO';**/
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import AboutFour from '../container/About/AboutFour';
import Video from '../container/Video/Video';
import AboutFive from '../container/About/AboutFive';
import TestimonialContainer from '../container/Testimonial/TestimonialContainer';
import CallToActionTwo from '../container/CallToAction/CallToActionTwo';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import MetaTags from 'react-meta-tags';

const AboutLivasys = () => {
    return (
        <React.Fragment>
            <MetaTags>
                <title>About Us - Livasys IT Solutions | Hyderabad | India</title>
                <meta name="description" content="A one-stop solution for all your digital needs. From web design and development to digital marketing, graphic design, domain and hosting services." />
                <meta name="keywords" content="livasys, livasys it solutions, it company, it company in hyderabad, it company in india, best it company in hyderabad, top it company in hyderabad, best it company in india, top it company in india" />
                <meta property="og:title" content="About Us - Livasys IT Solutions | Hyderabad | India" />
                <meta property="og:image" content="images/blog/770/32.webp" />
            </MetaTags>
            
            <Header />
            <Breadcrumb 
                //image="images/bg/breadcrumb-bg.webp"
                title="About Livasys"
                content="Home"
                contentTwo="About Us"
            />
            <AboutFour />
            <Video />
            <AboutFive />
            <TestimonialContainer classOption="bg-primary-blue" />
            <CallToActionTwo />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default AboutLivasys;



