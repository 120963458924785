import React from 'react';
// import SEO from '../components/SEO';
import MetaTags from 'react-meta-tags';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
//import AboutFour from '../container/About/AboutFour';
//import Video from '../container/Video/Video';
import AndroidAppDevelopmentContainer from '../container/AndroidAppDevelopment/AndroidAppDevelopmentContainer';
//import TestimonialContainer from '../container/Testimonial/TestimonialContainer';
import CallToActionTwo from '../container/CallToAction/CallToActionTwo';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';


const AndroidAppDevelopment = () => {
    return (
        <React.Fragment>
            <MetaTags>
                <title>Android Application Development Services in Hyderabad India</title>
                <meta name="description" content="Enhance Your User Experience and Improve Accessibility with our Best Responsive Web Design Services in Hyderabad India. We Ensure your Website remains Effective." />
                <meta name="keywords" content="responsive web design, responsive design, responsive website, mobile web design, responsive web design company, responsive web design services, mobile friendly website designs, mobile web design, mobile friendly website designs, mobile web development, web and mobile app development, web and mobile app development company, mobile web site design" />
                <meta property="og:title" content="Top-Rated Responsive Web Design Services in Hyderabad India" />
                <meta property="og:image" content="images/blog/770/32.webp" />
            </MetaTags>
            <Header />
            <Breadcrumb 
                title="Android App Development"
                content="Home"
                contentTwo="Android App Development"
            />
            {/* <AboutFour />
            <Video /> */}
            <AndroidAppDevelopmentContainer />
            {/* <TestimonialContainer classOption="bg-primary-blue" /> */}
            <CallToActionTwo />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default AndroidAppDevelopment;



