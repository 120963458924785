// import React, { useEffect, useRef } from "react";
// import React, { useState, useEffect, useRef } from "react";
import {Link} from "react-router-dom";
//import Typed from 'react-typed';
// import Parallax from 'parallax-js';
// import ModalVideo from "react-modal-video";

const HomeAboutThree = () => {
    // const [isOpen, setOpen] = useState(false)
    // const sceneEl = useRef(null);
    // useEffect(() => {
    //     const parallaxInstance = new Parallax(sceneEl.current, {
    //     relativeInput: true,
    //     })
        
    //     parallaxInstance.enable();

    //     return () => parallaxInstance.disable();

    // }, [])
    return (
        <div className="video-section section section-padding-t90-b100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 m-auto" data-aos="fade-up">
                        <div className="about-content-full text-left mb-lg-2 mb-0">
                        {/* <h2 className="title ah-headline">Digital Marketing is more than just…<br/> amazing {" "} 
                            <Typed
                                strings={[
                                    'Branding and Logos',
                                    'Google Ads',
                                    'Website Optimisation',
                                    'SEO',
                                    'Content Creation',
                                    'Website Design']}
                                    typeSpeed={100}
                                    backSpeed={110}
                                    loop  /> </h2> */}
                            <img src={process.env.PUBLIC_URL + "/images/faq/first-image.webp"} width="510" height="450" alt="Livasys Web Solutions" />
                        </div>
                    </div>
                    <div className="col-lg-6 m-auto" data-aos="fade-up">
                        <div className="about-content-full text-left mb-lg-2 mb-0">
                            <h1 className="title">Results-driven Web & Digital Marketing Solutions that elevate your brand.</h1>
                            <p>We provide a different approach to Build Websites and Digital Marketing Services to grow your business. We provide the best website design services with our experienced and best website designers in Hyderabad specialized in responsive and user friendly websites for various Indian and International businesses <span className="unique">(since 2012)</span>, Contact Now for a FREE Consultation with our Website Experts for a Website Design or Digital Marketing requirements for your business.</p>
                            <p>We’re a dedicated team of experts—marketing strategists, SEO specialists, developers, designers, copywriters, content creators, and media buyers—here to make sure your brand stands out, if you think you are at the correct place <Link to={process.env.PUBLIC_URL + "/about-livasys"} className="link"> <mark>read more about Livasys</mark> </Link>.</p>
                        </div>
                    </div>

                    {/* <div className="col-12">
                        <div className="video-popup-area">
                            <div className="skill-video">
                                <img className="image" src={process.env.PUBLIC_URL + "images/video/about-video.webp"}  alt="video popup" />
                                <button className="icon" onClick={()=> setOpen(true)}><i className="fas fa-play"></i></button>
                                <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="ewcV2OGIvaU" onClose={() => setOpen(false)}/>
                            </div>

                            <div className="shape shape-1" id="scene" ref={sceneEl}>
                                <span data-depth="1">
                                    <img src={process.env.PUBLIC_URL + "images/shape-animation/video-shape-1.webp"} width="141" height="156" alt="Livasys" />
                                </span>
                            </div>
                        </div>
                    </div> */}

                </div>

            </div>
        </div>
    )
}

export default HomeAboutThree;
