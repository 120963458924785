//import {useState, useEffect, useRef} from "react";
import SectionTitleTwo from '../../components/SectionTitles/SectionTitleTwo';
import AccordionWrap from '../../components/Accordion/AccordionWrap.jsx';
//import Parallax from 'parallax-js';

const Faq = () => {
    return (
        <div className="faq-section section section-padding-top bg-primary-blue">
            <div className="container">
                <div className="row row-cols-lg-1 row-cols-1 mb-n6">
                    <div className="col mb-12" data-aos="fade-up">
                        <div className="faq-content">
                            <SectionTitleTwo 
                                subTitle=""
                                title="Got a Question?"
                            />

                            <AccordionWrap />
                            
                        </div>
                    </div>

                    {/* <div className="col mb-6 pl-xl-12" data-aos="fade-up" data-aos-delay="300">
                        <div className="about-image-area faq-image-area">
                            <div className="about-image right-n50">
                                <Tilt scale={scale} transitionSpeed={4000}>
                                    <img src={process.env.PUBLIC_URL + "/images/faq/faq-2.webp"} width="370" height="370" alt="Livasys IT Solutions" />
                                </Tilt>
                            </div>
                            <div className="about-image">
                                <Tilt scale={scale} transitionSpeed={4000}>
                                    <img src={process.env.PUBLIC_URL + "/images/faq/faq-1.webp"} width="335" height="500" alt="Livasys Web Solutions" />
                                </Tilt>
                            </div>
                            <div className="shape shape-1" id="scene" ref={sceneEl}>
                                <span data-depth="1"><img src={process.env.PUBLIC_URL + "/images/shape-animation/video-shape-1.webp"} width="141" height="156" alt="" /></span>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Faq;
