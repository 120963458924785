import PropTypes from "prop-types";
import React from 'react';
//import {Link} from "react-router-dom";
//import BlogDetails from '../../components/Blog/BlogDetails.jsx';
//import Comment from '../../components/Comment/Comment.jsx';
import SidebarWrap from '../../components/Sidebar/SidebarWrap.jsx';
import SidebarWidget from '../../components/Sidebar/SidebarWidget.jsx';
import SidebarBanner from '../../components/Sidebar/SidebarBanner.jsx';
//import SidebarTitle from '../../components/Sidebar/SidebarTitle';
//import SidebarSearch from '../../components/Sidebar/SidebarSearch.jsx';
//import SidebarCategories from '../../components/Sidebar/SidebarCategories.jsx';
//import SidebarPost from '../../components/Sidebar/SidebarPost.jsx';
//import SidebarTag from '../../components/Sidebar/SidebarTag.jsx';

const WordpressTrainingContainer = () => {
    return (
        <div className="section section-padding fix">
            <div className="container">
                <div className="row mb-n10">

                    <div className="col-lg-8 col-12 order-lg-1 mb-10">
                        <div className="row row-cols-1 no-gutters">
                        <div className="blog-3 blog-details col" data-aos="fade-up">
            <div className="thumbnail">
                <img className="w-100" src="../images/blog/770/19.webp" alt="Blog Image" />
            </div>
            <div className="info">
                <h3 className="title">WordPress Training</h3>
                <div className="desc">
                    <p><strong>Livasys IT Solutions offers wordpress training with experts in real-time and live projects. Our wordpress course gives you indepth knowledge about wordpress and makes you an expert into wordpress.</strong></p>

                    <p>Take a web designing training with a hands-on approach to learning and building responsive websites from scratch for all desktop, mobile, and tablet platforms. </p>

                    <p>The course teaches you all the concepts of web design from scratch, the basics, planning process, design ideas, HTML, cascading style sheets, web publishing, and more.</p>

                    <p>Our online web design classes conclude with hands-on exercises to help you apply your skills and knowledge to real-life business situations. </p>

                    <p>Our online web design classes conclude with hands-on exercises to help you apply your skills and knowledge to real-life business situations. </p>

                    <p>The Web Design Curriculum is for people who want to study online web design courses together with development and design skills. </p>
                    <p>The program focuses on how to plan, design, design, build and deploy professional websites. So that graduates have a solid practical knowledge of the technologies in question.</p>
                   
                    <h3>Benefits from Web Designing course online:</h3>
                    <p>We teach you the concepts of HTML5, BootStrap 4, Javascript, tools and website hosting, user interface design, CSS 3.0, and Wireframe.</p>

                    <ul>
                        <li>With a decent salary, entry-level designers can work as web designers, web developers, UX designers, UI designers, and FrontEnd web designers with this online web design training.</li>
                        <li>We provide the necessary business insight before entering the IT world.  </li>
                        <li>Web Design Training will teach you how to interact with customers and enrich collaborative learning. </li>
                        <li>Our online web design curriculum allows students to create responsive designs with or without the Bootstrap framework.  </li>
                    </ul>
                    <h3>Opportunities after the web designing course:</h3>
                    <p>After completing the online web designing course, students can work as: </p>
                    <ul>
                        <li>Web Designer</li>
                        <li>UI Designer</li>
                        <li>UX Designer</li>
                        <li>Graphic Designer</li>
                        <li>Front end Web Designer </li>
                    </ul>
                    <p>Websites are the face of almost any business, that is why companies hire website designers to develop and maintain their virtual brand presence through websites. As mentioned above, the emergence of new websites is boosting the demand for web designers. </p>
                    <p>The Internet has been around for a long time to survive and thrive. These reasons assure you that starting a career in web design will bring you a bright future. Take an online web design course with Livasys IT Solutions and start your career now! </p>



                </div>
            </div>
        </div>

                        </div>
                    </div>

                    <div className="col-lg-4 col-12 order-lg-2 mb-10">
                        <SidebarWrap>
                            {/* <SidebarWidget>
                                <SidebarSearch />
                            </SidebarWidget>
                            <SidebarWidget>
                                <SidebarTitle title="Categories" />
                                 <SidebarCategories />
                            </SidebarWidget> */}
                            {/* <div className="sidebar-widget">
                                <h3 className="sidebar-widget-title sidebar-widget-title">Our Services</h3>
                                <div className="sidebar-widget-content">
                                <ul className="sidebar-widget-cate-list">
                                        <li><a href="/best-web-designing-company"><span className="text">Web Designing</span></a></li>
                                        <li><a href="/web-development-company"><span className="text">Web Development</span></a></li>
                                        <li><a href="/web-maintainance-company"><span className="text">Website Maintenance</span></a></li>
                                        <li><a href="/website-redesign-agencies"><span className="text">Website ReDesign</span></a></li>
                                        <li><a href="/wordpress-development"><span className="text">WordPress Development</span></a></li>
                                        <li><a href="/digital-marketing-agency-in-hyderabad"><span className="text">Digital Marketing</span></a></li>
                                        <li><a href="/search-engine-optimization-services-in-hyderabad"><span className="text">SEO</span></a></li>
                                        <li><a href="/web-hosting-service-provider"><span className="text">Web Hosting</span></a></li>
                                        <li><a href="/web-application-development"><span className="text">Web Application Development</span></a></li>
                                        <li><a href="/logo-design-services"><span className="text">Logo Designing</span></a></li>
                                        <li><a href="/social-media-marketing-company-in-hyderabad"><span className="text">Social Media Marketing</span></a></li>
                                        <li><a href="/wordpress-training-in-hyderabad"><span className="text">WordPress Training</span></a></li>
                                        <li><a href="/web-designing-course-in-hyderabad"><span className="text">Web Development Training</span></a></li>
                                    </ul>
                                </div>
                            </div> */}
                            {/* <SidebarWidget>
                                <SidebarTitle classOption="mb-2" title="Popular Posts" />
                                <SidebarPost />
                            </SidebarWidget> */}
                            <SidebarWidget>
                                <SidebarBanner />
                            </SidebarWidget>
                            {/* <SidebarWidget>
                                <SidebarTitle title="Popular tags" />
                                <SidebarTag />
                            </SidebarWidget> */}
                        </SidebarWrap>
                    </div>

                </div>
            </div>
        </div>
    )
}
WordpressTrainingContainer.propTypes = {
    data: PropTypes.object
};

export default WordpressTrainingContainer;
