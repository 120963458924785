import React from 'react';
import {NavLink} from 'react-router-dom';
import {
    getClosest,
    getSiblings,
    slideToggle,
    slideUp,
} from "../../../utils";

const MobileNavMenu = () => {
    const onClickHandler = (e) => {
        const target = e.currentTarget;
        const parentEl = target.parentElement;
        if (
            parentEl?.classList.contains("menu-toggle") ||
            target.classList.contains("menu-toggle")
        ) {
            const element = target.classList.contains("icon")
                ? parentEl
                : target;
            const parent = getClosest(element, "li");
            const childNodes = parent.childNodes;
            const parentSiblings = getSiblings(parent);
            parentSiblings.forEach((sibling) => {
                const sibChildNodes = sibling.childNodes;
                sibChildNodes.forEach((child) => {
                    if (child.nodeName === "UL") {
                        slideUp(child, 1000);
                    }
                });
            });
            childNodes.forEach((child) => {
                if (child.nodeName === "UL") {
                    slideToggle(child, 1000);
                }
            });
        }
    };
    return (
        <nav className="site-mobile-menu">
            <ul>
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/"}><span className="menu-text">Home</span></NavLink>
                </li>
                
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/about-livasys"}><span className="menu-text">About Us</span></NavLink>
                </li>
                <li className="has-children">
                    <NavLink to={process.env.PUBLIC_URL + "/services"}><span className="menu-text">Services</span></NavLink>
                    <span className="menu-toggle" onClick={onClickHandler}><i className="icon far fa-angle-down"></i></span>
                    <ul className="sub-menu">
                        <li><NavLink to={process.env.PUBLIC_URL + "/best-web-designing-company"}><span className="menu-text">Web Designing</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/web-development-company"}><span className="menu-text">Web Development</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + `/wordpress-development`}><span className="menu-text">WordPress Development</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + `/graphic-design-services`}><span className="menu-text">Graphic Designing</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/logo-design-services"}><span className="menu-text">Logo Designing</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/web-hosting-service-provider"}><span className="menu-text">Web Hosting</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + `/web-application-development`}><span className="menu-text">Web Application</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/digital-marketing-agency-in-hyderabad"}><span className="menu-text">Digital Marketing</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + `/search-engine-optimization-services-in-hyderabad`}><span className="menu-text">SEO </span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + `/local-seo-services-hyderabad`}><span className="menu-text">Local SEO</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/domain-registration-services"}><span className="menu-text">Domain Registration</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/android-app-development"}><span className="menu-text">Android App Development</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/ios-app-development"}><span className="menu-text">IOS App Development</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/cyber-security-services"}><span className="menu-text">Cyber Security</span></NavLink></li>
                    </ul>
                </li>
                <li className="has-children">
                    <NavLink to={process.env.PUBLIC_URL + "/courses"}><span className="menu-text">Courses</span></NavLink>
                    <span className="menu-toggle" onClick={onClickHandler}><i className="icon far fa-angle-down"></i></span>
                    <ul className="sub-menu">
                        <li><NavLink to={process.env.PUBLIC_URL + "/web-designing-course-in-hyderabad"}><span className="menu-text">Web Designing Course</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + `/digital-marketing-course-in-hyderabad`}><span className="menu-text"> Digital Marketing Course</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/php-training-in-hyderabad"}><span className="menu-text">PHP Course</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/wordpress-training-in-hyderabad"}><span className="menu-text">WordPress Course</span></NavLink></li>
                    </ul>
                </li>
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/our-clients"}><span className="menu-text">Our Clients</span></NavLink>
                </li>
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/internships-hyderabad"}><span className="menu-text">Internships</span></NavLink>
                </li>
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/contact"}><span className="menu-text">Contact Us</span></NavLink>
                </li>
            </ul>
        </nav>
    )
}

export default MobileNavMenu;
