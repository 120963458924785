import PropTypes from "prop-types";
import React from 'react';
import SectionTitle from '../../components/SectionTitles/SectionTitle';
//import {Link} from "react-router-dom";
//import BlogDetails from '../../components/Blog/BlogDetails.jsx';
//import Comment from '../../components/Comment/Comment.jsx';
//import SidebarWrap from '../../components/Sidebar/SidebarWrap.jsx';
//import SidebarWidget from '../../components/Sidebar/SidebarWidget.jsx';
//import SidebarBanner from '../../components/Sidebar/SidebarBanner.jsx';
import ServiceData from '../../data/service/serviceDetails.json';
import ServiceItemTwo from '../../components/Service/ServiceItemTwo.jsx';
//import SidebarTitle from '../../components/Sidebar/SidebarTitle';
//import SidebarSearch from '../../components/Sidebar/SidebarSearch.jsx';
//import SidebarCategories from '../../components/Sidebar/SidebarCategories.jsx';
//import SidebarPost from '../../components/Sidebar/SidebarPost.jsx';
//import SidebarTag from '../../components/Sidebar/SidebarTag.jsx';

const ServicesContainer = () => {
    return (
        <div className="section section-padding fix">
            <div className="container">

            <SectionTitle
                    headingOption="title fz-32"
                    title=""
                />
                
                            <div className="row row-cols-lg-3 row-cols-md-2 row-cols-1 mb-n6">

                {ServiceData && ServiceData.map((single, key) => {
                        return(
                            <div key={key} className="col mb-6" data-aos="fade-up" data-aos-delay="300">
                                <ServiceItemTwo classOption="box-border" data={single} key={key} />
                            </div>
                        ); 
                })}

                </div>


              
            </div>
        </div>
    )
}
ServicesContainer.propTypes = {
    data: PropTypes.object
};

export default ServicesContainer;
