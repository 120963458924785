import {useState, useEffect, useRef} from "react";
import SectionTitleTwo from '../../components/SectionTitles/SectionTitleTwo';
// import {Link} from "react-router-dom";
import Tilt from 'react-parallax-tilt';
import Parallax from 'parallax-js';

const AboutFive = () => {
    const [scale] = useState(1.04);
    const sceneEl = useRef(null);

    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
        relativeInput: true,
        })
        
        parallaxInstance.enable();

        return () => parallaxInstance.disable();

    }, [])
    return (
        <div className="section section-padding-top section-padding-bottom-50">
            <div className="container">

                <div className="row">

                    <div className="col-xl-7 col-lg-6 col-12" data-aos="fade-up">
                        <div className="about-image-area">
                            <div className="about-image">
                                <Tilt scale={scale} transitionSpeed={4000}>
                                    <img src={process.env.PUBLIC_URL + "images/about/about-3.webp"} alt="livasys it solutions" />
                                </Tilt>
                            </div>
                            <div className="about-image">
                                <Tilt scale={scale} transitionSpeed={4000}>
                                    <img src={process.env.PUBLIC_URL + "images/about/about-4.webp"} alt="livasys it solutions" />
                                </Tilt>
                            </div>

                            <div className="shape shape-1" id="scene" ref={sceneEl}>
                                <span data-depth="1"><img src={process.env.PUBLIC_URL + "images/shape-animation/about-shape-1.webp"} alt="" /></span>
                            </div>

                        </div>
                    </div>

                    <div className="col-xl-5 col-lg-6 col-12" data-aos="fade-up" data-aos-delay="300">
                        <div className="about-content-area">
                            <SectionTitleTwo 
                                subTitle="Our solutions begin with brand research"
                                title="We are here to manage your online presence with experience"
                            />

                            <p>In addition to our web design and digital marketing expertise, we also provide reliable domain registration and hosting services. We understand that a seamless online experience requires a solid foundation, which is why we offer secure and fast hosting solutions tailored to your specific needs. Our domain registration services ensure that your brand is represented with a professional online presence. Livasys IT Solutions helps organizations improve by leveraging the power of modern technology, cost-Effective Web Designs & eCommerce Solutions.</p><br/><br/>
                            
                            {/* <p>Livasys IT Solutions helps organizations improve by leveraging the power of modern technology, cost-Effective Web Designs & eCommerce Solutions.</p> */}
                        </div>
                    </div>

                    <div className="col-xl-12 col-lg-12 col-12" data-aos="fade-up">
                    <div className="about-content-area">
                            <p>  With our full approach and commitment to delivering exceptional results, we have earned a reputation as a trusted partner for businesses seeking to succeed in the digital world. Whether you&lsquo;re a small startup or an established enterprise, we have the knowledge and skills to help you achieve your online goals.</p>
<br/>
                            <p>Partner with us and unlock the full potential of your online presence. Let us handle the technical complexities while you focus on growing your business. Contact us today to discuss how we can take your digital strategy to the next level.</p>
 </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default AboutFive;
